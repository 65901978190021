import { cfAccessHeaders } from '@mono/data-cf-access-headers';
import generatedGraphqlGatewayTypes from '@mono/data-codegen/client/graphql-gateway/graphql';
import generatedContentfulTypes from '@mono/data-codegen/contentful';
import generatedContentfulLandingPageTypes from '@mono/data-codegen/contentful-landing-page';
import generatedMonolithTypes from '@mono/data-codegen/monolith';

import envsInternalExternal from './envsInternalExternal';

export function getApolloServiceConfig() {
  const apolloServiceConfig = {
    monolith: {
      generatedTypes: generatedMonolithTypes,
      uri: envsInternalExternal.MONOLITH_GRAPHQL_URL,
      headers: cfAccessHeaders,
    },
    contentful: {
      generatedTypes: generatedContentfulTypes,
      uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/master`,
      headers: {
        Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
      },
    },
    graphqlGateway: {
      generatedTypes: generatedGraphqlGatewayTypes,
      uri: envsInternalExternal.GRAPHQL_GATEWAY_URL,
    },
    contentfulLandingPage: {
      generatedTypes: generatedContentfulLandingPageTypes,
      uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_LANDING_PAGE_SPACE_ID}/environments/master`,
      headers: {
        Authorization: `Bearer ${process.env.CONTENTFUL_LANDING_PAGE_ACCESS_TOKEN}`,
      },
    },
  };
  return apolloServiceConfig;
}
