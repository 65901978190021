import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { getHeaderResourcesList } from '../../../lib/resourcesList';
import {
  AppHeaderAction,
  AppHeaderResourcesDropdownItem,
  StyledDropdown,
} from '../../shared';
import { AppHeaderDropdownProvider } from '../AppHeaderDropdownProvider';
import { AppHeaderDropdownNavButton } from '../AppHeaderNavButton/AppHeaderDropdownNavButton';
import { useAppHeaderContext } from '../AppHeaderProvider';
import { AppHeaderResourcesSection } from '../AppHeaderResourcesSection';

export type AppHeaderResourceDropdownProps = AppHeaderAction & {
  item: AppHeaderResourcesDropdownItem;
  isAnon: boolean;
};

export const AppHeaderResourcesDropdown: React.FC<
  AppHeaderResourceDropdownProps
> = ({ action, item, isAnon }) => {
  const { text } = item;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { lastOpenedDropdown, setLastOpenedDropdown } = useAppHeaderContext();

  // Add scrollbar for short screens after transition (otherwise scrollbar appears and dissapears for adequate heights during transition)
  const [animationCompleteStyles, setAnimationCompleteStyles] = useState(false);

  const headerResourcesList = getHeaderResourcesList();

  const animationFinished = () => {
    setAnimationCompleteStyles(isOpen);
    if (isOpen && dropdownRef.current) {
      dropdownRef.current.scrollTop = 0;
    }
  };

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const handleOnClick = (event: React.MouseEvent) => {
    toggleIsOpen();
    if (!isOpen) {
      action(event, item);
      if (setLastOpenedDropdown) {
        setLastOpenedDropdown(text);
      }
    }
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
    buttonRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (lastOpenedDropdown !== text && isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOpenedDropdown]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | Event) {
      const container = containerRef?.current;
      const button = buttonRef?.current;
      if (
        isOpen &&
        container &&
        !container.contains(event.target as Node) &&
        button &&
        !button.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('blur', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('blur', handleClickOutside);
    };
  }, [containerRef, handleClose, isOpen]);

  return (
    <AppHeaderDropdownProvider handleClose={handleClose}>
      <AppHeaderDropdownNavButton
        buttonRef={buttonRef}
        handleOnClick={handleOnClick}
        isOpen={isOpen}
        title={text}
      />
      <StyledDropdown
        tabIndex={-1} // prevent from interfering with AppHeader focus logic
        ref={dropdownRef}
        style={{
          top: '3.5rem',
          minWidth: '64rem',
          left: isAnon ? '-14.5rem' : '-19.5rem',
          overflowY: 'auto',
          maxHeight: animationCompleteStyles ? '85vh' : 0,
        }}
        initial={{ borderWidth: 0, height: 0 }}
        animate={{
          borderWidth: isOpen ? 1 : 0,
          height: isOpen ? 'fit-content' : 0,
        }}
        transition={{ duration: 0.175 }}
        aria-hidden={!isOpen}
        data-testid="resources-menu-dropdown"
        onAnimationComplete={animationFinished}
      >
        <AppHeaderResourcesSection
          action={action}
          ref={containerRef}
          id={`menu-container${item.text}`}
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          resources={headerResourcesList}
        />
      </StyledDropdown>
    </AppHeaderDropdownProvider>
  );
};
