import {
  ContentContainer,
  FillButton,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { useState } from 'react';
import * as React from 'react';

import { AppHeaderLink } from '../../AppHeader/AppHeaderElements/AppHeaderLink';
import { AppHeaderLinkSections } from '../../AppHeader/AppHeaderElements/AppHeaderLinkSections';
import {
  AppHeaderAction,
  AppHeaderClickHandler,
  AppHeaderDropdownItem,
  AppHeaderItem,
} from '../../AppHeader/shared';
import { login, signUp } from '../../GlobalHeader/GlobalHeaderItems';
import { AppHeaderSubNavMobile } from '../AppHeaderSubMenuMobile';
import { MobileUlWrapper } from '../AppHeaderSubMenuMobile/elements';
import { AppHeaderSubMenuRow } from '../AppHeaderSubMenuTarget';

export type AppHeaderMainMenuMobileProps = AppHeaderAction & {
  items: AppHeaderItem[];
  getItemType: (type: string | undefined) => void;
  isAnon: boolean;
  handleCloseMainMenu: () => void;
};

export const AppHeaderMainMenuMobile: React.FC<
  AppHeaderMainMenuMobileProps
> = ({ action, items, getItemType, isAnon, handleCloseMainMenu }) => {
  const [subMenuItem, setSubMenuItem] = useState<AppHeaderDropdownItem>();

  const openSubMenu = (
    event: React.MouseEvent,
    item: AppHeaderDropdownItem
  ) => {
    action(event, item);
    setSubMenuItem(item);
    getItemType(item.type);
  };

  const closeSubMenu = () => {
    setSubMenuItem(undefined);
    getItemType(undefined);
  };

  const mapItemToElement = (
    item: AppHeaderItem,
    action: AppHeaderClickHandler
  ) => {
    switch (item.type) {
      case 'link':
        return (
          <AppHeaderLink action={action} item={item} key={item.id} showIcon />
        );
      case 'dropdown':
      case 'profile-dropdown':
      case 'catalog-dropdown':
        return (
          <AppHeaderSubMenuRow
            key={item.id}
            item={item}
            openSubMenu={openSubMenu}
          />
        );
      case 'resources-simple-dropdown':
        return (
          <>
            <Text as="h2" fontSize={22} mt={16} mb={16}>
              {item.text}
            </Text>
            <AppHeaderLinkSections
              action={action}
              item={item}
              showIcon
              mobile
            />
          </>
        );
      case 'fill-button':
        return (
          <FillButton
            data-testid={item.dataTestId}
            href={item.href}
            onClick={(event: React.MouseEvent) => action(event, item)}
            mt={24}
            key={item.id}
          >
            {item.text}
          </FillButton>
        );
      case 'text-button':
        return (
          <TextButton
            mt={16}
            key={item.id}
            data-testid={item.dataTestId}
            href={item.href}
            onClick={(event: React.MouseEvent) => action(event, item)}
          >
            {item.text}
          </TextButton>
        );
      default:
        return <></>;
    }
  };

  return subMenuItem ? (
    <AppHeaderSubNavMobile
      handleCloseSubMenu={closeSubMenu}
      action={action}
      item={subMenuItem}
      handleCloseMainMenu={handleCloseMainMenu}
    />
  ) : (
    <ContentContainer
      role="navigation"
      aria-label="site navigation"
      tabIndex={-1}
    >
      <MobileUlWrapper as="div">
        {items.map((item) => mapItemToElement(item, action))}
        {isAnon && (
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              paddingTop: '16px',
              borderTop: '1px solid var(--border-tertiary)',
            }}
          >
            <FillButton
              data-testid={signUp.dataTestId}
              href={signUp.href}
              onClick={(event: React.MouseEvent) => action(event, signUp)}
              key={signUp.id}
            >
              {signUp.text}
            </FillButton>

            <TextButton
              key={login.id}
              data-testid={login.dataTestId}
              href={login.href}
              onClick={(event: React.MouseEvent) => action(event, login)}
            >
              {login.text}
            </TextButton>
          </div>
        )}
      </MobileUlWrapper>
    </ContentContainer>
  );
};
