import { BannerProps } from '@mono/brand';
import { useBrandedBannerUserLazyQuery } from '@mono/data-codegen/monolith';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { useSkipBrandedBannerContext } from '~/libs/brandedBanner/skipBrandedBanner';
import { trackUserClick } from '~/libs/tracking';

import { isNonNullBanner } from '../types';
import { BrandedBannerData, fetchBannerData } from './fetchBannerData';
import { shouldShowForGeo } from './shouldShowForGeo';
import { shouldShowForPage } from './shouldShowForPage';
import { shouldShowForUser } from './shouldShowForUser';

export const label = 'branded-banner';

export const useBrandedBanner = (): BannerProps | undefined => {
  const skipBrandedBanner = useSkipBrandedBannerContext();

  const [fetchBrandedBannerUserData, { data: userData }] =
    useBrandedBannerUserLazyQuery();

  const [closedBrandedBanners, setClosedBrandedBanners] = useLocalStorage<
    Record<string, boolean>
  >('closedBrandedBanners', {});

  const [bannerData, setBannerData] = useState<BrandedBannerData | undefined>(
    undefined
  );

  useEffect(() => {
    if (!skipBrandedBanner && !bannerData) {
      fetchBrandedBannerUserData();
      fetchBannerData().then((data) => setBannerData(data));
    }
  }, [skipBrandedBanner, bannerData, fetchBrandedBannerUserData]);

  if (skipBrandedBanner) {
    return undefined;
  }

  const currentPath =
    typeof window !== 'undefined' ? window?.location?.pathname : '';

  const bannerToShow =
    bannerData &&
    userData &&
    bannerData.brandedBannerCollection?.items.find(
      (banner) =>
        isNonNullBanner(banner) &&
        shouldShowForPage(
          currentPath,
          banner.limitToPages,
          banner.excludedPages
        ) &&
        shouldShowForGeo(bannerData.userLocale, banner.geoTarget) &&
        !!banner.audiences &&
        shouldShowForUser(banner.audiences, userData.me) &&
        closedBrandedBanners &&
        !closedBrandedBanners[banner.sys.id]
    );

  if (!bannerToShow || !bannerToShow.markdownContent) {
    return undefined;
  }

  const {
    backgroundColor,
    markdownContent,
    sys: { id },
  } = bannerToShow;

  return {
    variant: backgroundColor as BannerProps['variant'],
    id: label,
    role: 'complementary',
    'aria-label': label,
    onClose: () => {
      setClosedBrandedBanners({
        ...closedBrandedBanners,
        [bannerToShow.sys.id]: true,
      });
      trackUserClick({
        target: 'branded_banner_closed',
        context: id,
      });
    },
    onCtaClick: () =>
      trackUserClick({
        target: 'branded_banner_cta',
        context: id,
      }),
    children: markdownContent,
  };
};
