import { BrandedBannerQuery } from '@mono/data-codegen/contentful';
import { logWarn } from '@mono/data-logging';

export type BrandedBannerData = BrandedBannerQuery & { userLocale: string };

export const fetchBannerData = async (): Promise<
  BrandedBannerData | undefined
> => {
  const res = await fetch('/api/portal/branded-banner', {
    credentials: 'include',
    mode: 'cors',
  });
  if (!res.ok) {
    const msg = await res.text();
    logWarn(`Client side branded banner data fetch failed: ${msg}`);
    return undefined;
  }
  return res.json() as Promise<BrandedBannerData>;
};
