import { FeatureFlag } from '@mono/data-feature-flags';
import React, { useContext } from 'react';

export type FeatureFlagsContextValue = {
  featureFlags: FeatureFlag[];
};

export const FeatureFlagsContext =
  React.createContext<FeatureFlagsContextValue>({
    featureFlags: [],
  });

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
