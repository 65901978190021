import { datadogLogs } from '@datadog/browser-logs';
import { FeatureFlag } from '@mono/data-feature-flags';
import { logError } from '@mono/data-logging';
import { useOptimizelyFeature } from '@mono/data-optimizely';
import { userIsLoggedIn, useUser } from '@mono/data-user';
import { initializeTrackingIntegrations, TrackingWindow } from '@mono/tracking';
import React, { useEffect } from 'react';

/**
 *
 * Wrapper component around `initializeTrackingIntegrations` that allows
 * us to use `useUser` in the App component while still using the SWR cache provider
 */
export const TrackingIntegrations: React.FC = () => {
  const user = useUser();
  const hasUser = typeof user !== 'undefined';
  const isFidesEnabled = useOptimizelyFeature(
    FeatureFlag.FIDES_CONSENT_MANAGEMENT
  );

  useEffect(() => {
    if (user) {
      if ('id' in user) datadogLogs.setGlobalContextProperty('userId', user.id);
      const searchParams = new URLSearchParams(window.location.search);
      let enablePartytown = false;
      if (searchParams.get('partytown') === 'true') {
        enablePartytown = true;
      }
      initializeTrackingIntegrations({
        environment: process.env.NEXT_PUBLIC_ENV,
        scope: window as TrackingWindow,
        optedOutExternalTracking:
          userIsLoggedIn(user) && user.optedOutExternalTracking,
        enablePartytown,
        isFidesEnabled,
      }).catch(logError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUser]);

  return null;
};
