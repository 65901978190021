import {
  Badge,
  Box,
  ContentContainer,
  FlexBox,
  FocusTrap,
  IconButton,
  Menu,
  MenuItem,
  Shimmer,
  StrokeButton,
  Text,
} from '@codecademy/gamut';
import { MiniDeleteIcon, SearchIcon } from '@codecademy/gamut-icons';
import { css, theme, useCurrentMode } from '@codecademy/gamut-styles';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { useOnEscHandler } from '../shared';
import { searchPlaceholder } from './consts';
import { PopularContent, PopularSearches } from './DefaultResults';
import { QuizAndHelpCenterLinks } from './QuizAndHelpCenterLinks';
import { searchWorker } from './SearchWorker';
import type {
  AutocompleteSuggestion,
  SearchAsYouTypeResults,
} from './SearchWorker/types';
import { SearchPaneProps } from './types';

const Form = Box.withComponent('form');
const Input = Box.withComponent('input');

const QueryContainer = styled(ContentContainer)(
  css({
    display: 'flex',
    width: '100%',
    pt: 16,
    pb: { _: 0, md: 24 },
    px: { _: 24 },
  })
);

const SuggestionContainer = styled(ContentContainer)(
  css({
    pt: 16,
    pb: { _: 0, md: 24 },
    px: { _: 24 },
  })
);

const StyledInput = styled(Input)(
  css({
    outline: `none`,
    '&::placeholder': {
      textColor: theme.colors['text-secondary'] as never,
    },
  })
);

const InlineResultLi = styled(MenuItem)(
  css({
    pl: 0,
    py: 8,
    fontSize: 16,
    '&:focus-visible:after': {
      left: -4,
    },
  })
);

const InlineLoaderLi = styled.li(
  css({
    listStyleType: 'none',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  })
);

export const SemiboldSearchIcon = styled(SearchIcon)`
  overflow: visible !important;
  circle,
  path {
    stroke-width: 2px;
  }
  rect {
    transform: translate(-2px, -2px);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
`;

const EllipsisBox = styled(Box)`
  text-overflow: ellipsis;
`;

const HighlightedText = ({
  suggestion: { title, segments },
}: {
  suggestion: AutocompleteSuggestion;
}) => {
  const mode = useCurrentMode();
  const highlightColor = mode === 'dark' ? 'hyper' : 'yellow';

  return (
    <FlexBox>
      <EllipsisBox
        maxWidth="calc(100vw - 128px)"
        whiteSpace="pre"
        aria-hidden
        overflow="hidden"
      >
        {segments.map((segment, i) => (
          <Text
            key={`${title}:${i.toString()}`}
            lineHeight="title"
            {...(segment.highlight
              ? { bg: highlightColor, fontWeight: 'bold' }
              : {})}
          >
            {segment.value}
          </Text>
        ))}
      </EllipsisBox>
      <Text screenreader>{title}</Text>
    </FlexBox>
  );
};

type SearchAsYouTypeLoader = {
  shimmerRows: {
    key: string;
    shimmers: {
      key: string;
      width: number;
    }[];
  }[];
};

let loaderKey = 0;
function rndLoader() {
  // eslint-disable-next-line no-plusplus
  const key = loaderKey++;
  const loader: SearchAsYouTypeLoader = { shimmerRows: [] };
  for (let i = 0; i < 5; i++) {
    const row = {
      key: `${key}:${i}`,
      shimmers: [] as { key: string; width: number }[],
    };
    const words = Math.ceil(Math.random() * 3);
    for (let j = 0; j < words; j++) {
      const width = 12 + Math.round(Math.random() * 96);
      row.shimmers.push({
        key: `${key}:${j}`,
        width,
      });
    }
    loader.shimmerRows.push(row);
  }
  return loader;
}

export const SearchPane: React.FC<SearchPaneProps> = ({
  onSearch,
  onTrackingClick,
  searchButtonRef,
  toggleSearch,
  onSearchAsYouType,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState<
    AutocompleteSuggestion[]
  >([]);

  const [searchAsYouTypeResults, setSearchAsYouTypeResults] =
    useState<SearchAsYouTypeResults | null>(null);

  const searchAsYouTypeLoader = React.useMemo(
    () => (searchAsYouTypeResults === null ? rndLoader() : null),
    [searchAsYouTypeResults]
  );

  const closeSearch = async () => {
    await toggleSearch();
    if (searchButtonRef && searchButtonRef?.current) {
      await searchButtonRef.current?.focus();
    }
  };

  const onKeyDown = useOnEscHandler(closeSearch);

  const onMouseDownOutside = ({ target }: MouseEvent) => {
    const handleOutsideClick = () => {
      if (
        !document
          .querySelector('[data-testid="header-search-dropdown"]')
          ?.contains(target as HTMLElement) &&
        target !== searchButtonRef?.current
      ) {
        closeSearch();
      }

      target?.removeEventListener('mouseup', handleOutsideClick);
    };

    target?.addEventListener('mouseup', handleOutsideClick);
  };

  const navigateToSearch = (searchTerm: string, fromPrevSearch?: string) => {
    onSearch(searchTerm, fromPrevSearch);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setValue(evt.target.value);
  };

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    navigateToSearch(value, searchAsYouTypeResults?.searchId);
  };

  const clearInput = () => {
    setValue('');
    setAutoCompleteSuggestions([]);
  };

  useEffect(() => {
    inputRef.current?.focus();
    searchWorker.init();
  }, []);

  const valueTrimmed = value.trim();

  useEffect(() => {
    if (!valueTrimmed.length) {
      setSearchAsYouTypeResults(null);
      setAutoCompleteSuggestions([]);
      return;
    }

    let cancel = false;
    let clearAutocomplete = true;
    let clearSearchAsYouType = true;

    setTimeout(() => {
      /*
       * Wait 10 seconds to get a response for worker before showing loaders.
       * This prevents flickering loaders on quick or cached results.
       */
      if (cancel) return;
      if (clearAutocomplete) setAutoCompleteSuggestions([]);
      if (clearSearchAsYouType) setSearchAsYouTypeResults(null);
    }, 10);

    searchWorker.autocomplete(valueTrimmed).then((suggestions) => {
      clearAutocomplete = false;

      if (cancel) return; // text has changed since this request was made

      setAutoCompleteSuggestions(suggestions);
    });

    searchWorker.searchAsYouType(valueTrimmed).then((results) => {
      clearSearchAsYouType = false;

      if (cancel) return; // text has changed since this request was made

      setSearchAsYouTypeResults(results);

      // onSearchAsYouType is typically used for tracking
      if (onSearchAsYouType) {
        setTimeout(() => {
          /*
           * This timeout and cancel check is a debounce that ensures we do not
           * send tracking events for every keystroke, but only when the user
           * stops typing for 250ms. We previously used this debounce for the
           * search-as-you-type query as a whole, but this is not necessary
           * from a load standpoint since we have caching implemented on both
           * client and server. Applying the debounce only for this callback
           * allows us to maintain the most snappy user experience and still
           * have non-noisy tracking.
           */
          if (cancel) return;
          const { query, searchId, resultsCount, queryLoadTime } = results;
          onSearchAsYouType(query, searchId, resultsCount, queryLoadTime);
        }, 250);
      }
    });

    return () => {
      cancel = true;
    };
  }, [valueTrimmed, onSearchAsYouType]);

  return (
    <>
      <Box
        aria-hidden
        bg="shadow-secondary"
        height="100vh"
        position="fixed"
        // We add 5rem here in case there's some sort of branded banner above search
        // The search area is much taller than 5rem so this is a safe amount of padding
        top={`calc(${theme.elements.headerHeight} + 5rem)`}
        width={1}
      />
      <FocusTrap
        onEscapeKey={closeSearch}
        onClickOutside={onMouseDownOutside}
        allowPageInteraction
      >
        <Box
          bg="background"
          borderColorBottom="border-primary"
          borderColorTop="border-tertiary"
          borderStyle="solid"
          borderWidth="2px 0 1px"
          data-testid="header-search-dropdown"
          position="absolute"
          width="100%"
          maxHeight={{ _: 'calc(100vh - 63px)', md: 'calc(100vh - 80px)' }}
          overflow="auto"
        >
          <Box border="none" width="auto">
            <QueryContainer>
              <FlexBox
                alignItems="baseline"
                borderColor="gray-600"
                borderStyleBottom="solid"
                borderWidthBottom="1px"
                width="100%"
              >
                <SearchIcon
                  height={{ _: 20, md: 24 }}
                  width={{ _: 20, md: 24 }}
                />
                <Form
                  action="/search"
                  id="search-form"
                  ml={8}
                  onSubmit={handleSubmit}
                  width="100%"
                >
                  <StyledInput
                    autoFocus
                    background="none"
                    border="none"
                    color="text"
                    fontSize={{ _: 20, md: 26 }}
                    fontWeight="bold"
                    id="header-search-bar"
                    name="query"
                    onChange={handleChange}
                    onKeyDown={(e) => onKeyDown(e)}
                    placeholder={searchPlaceholder}
                    ref={inputRef}
                    type="search"
                    value={value}
                    width="100%"
                    autoComplete="off"
                  />
                </Form>
                <IconButton
                  icon={MiniDeleteIcon}
                  aria-label="Clear search"
                  tip="Clear search"
                  tipProps={{
                    alignment: 'bottom-center',
                    placement: 'floating',
                    narrow: true,
                    hideAriaToolTip: true,
                    zIndex: 2,
                  }}
                  onClick={clearInput}
                  size="small"
                />
              </FlexBox>
            </QueryContainer>
          </Box>
          <SuggestionContainer>
            {valueTrimmed.length > 0 ? (
              <>
                <FlexBox flexDirection="column" as="ul" listStyle="none" p={0}>
                  {autoCompleteSuggestions.map((s) => (
                    <InlineResultLi
                      key={s.title}
                      tabIndex={0}
                      onKeyDown={(evt) => {
                        if (evt.key === 'Enter') {
                          onTrackingClick('autocomplete_result', {
                            search_id: searchAsYouTypeResults?.searchId ?? '',
                            misc: JSON.stringify({ value: s.title }),
                          });
                          navigateToSearch(
                            s.title,
                            searchAsYouTypeResults?.searchId
                          );
                        }
                        closeSearch();
                      }}
                      onClick={() => {
                        onTrackingClick('autocomplete_result', {
                          search_id: searchAsYouTypeResults?.searchId ?? '',
                          misc: JSON.stringify({ value: s.title }),
                        });
                        navigateToSearch(
                          s.title,
                          searchAsYouTypeResults?.searchId
                        );
                        closeSearch();
                      }}
                    >
                      <SemiboldSearchIcon
                        mb={2 as 0}
                        size={14}
                        strokeWidth={8}
                        aria-hidden
                        mr={12}
                      />
                      <HighlightedText suggestion={s} />
                    </InlineResultLi>
                  ))}
                </FlexBox>
                {(searchAsYouTypeResults === null ||
                  searchAsYouTypeResults.top.length > 0) && (
                  <>
                    <Text as="h2" fontSize={20} mb={16} mt={24}>
                      Top results
                    </Text>
                    <FlexBox
                      flexDirection="column"
                      as="ul"
                      listStyle="none"
                      p={0}
                    >
                      {searchAsYouTypeResults === null
                        ? searchAsYouTypeLoader?.shimmerRows.map((r) => (
                            <InlineLoaderLi key={r.key}>
                              {r.shimmers.map((word) => (
                                <Shimmer
                                  height={30}
                                  py={8 as 0}
                                  key={word.key}
                                  width={word.width}
                                />
                              ))}
                              <Box
                                fontFamily="accent"
                                textColor="text-secondary"
                                fontSize={{ _: 10 as 16 }}
                                borderColor="border-secondary"
                                border={1}
                                borderRadius="xl"
                                px={16}
                                opacity={0.1}
                              >
                                &nbsp;
                              </Box>
                            </InlineLoaderLi>
                          ))
                        : searchAsYouTypeResults.top.map((s, i) => (
                            <InlineResultLi
                              key={`${
                                searchAsYouTypeResults.query
                              }:${i.toString()}`}
                              tabIndex={0}
                              role="link"
                              onKeyDown={(evt) => {
                                if (evt.key === 'Enter') {
                                  onTrackingClick('search_as_you_type_result', {
                                    search_id: searchAsYouTypeResults.searchId,
                                    slug: s.slug,
                                    ...(s.contentId
                                      ? { content_id: s.contentId }
                                      : {}),
                                  });
                                  closeSearch();
                                  window.location.assign(s.urlPath);
                                }
                              }}
                              onClick={() => {
                                onTrackingClick('search_as_you_type_result', {
                                  search_id: searchAsYouTypeResults.searchId,
                                  slug: s.slug,
                                  ...(s.contentId
                                    ? { content_id: s.contentId }
                                    : {}),
                                });
                                closeSearch();
                                window.location.assign(s.urlPath);
                              }}
                            >
                              <HighlightedText suggestion={s} />
                              <Badge size="sm" variant="tertiary" ml={12}>
                                {s.type}
                              </Badge>
                            </InlineResultLi>
                          ))}
                    </FlexBox>
                  </>
                )}
                {searchAsYouTypeResults?.top.length === 0 && (
                  <>
                    <Box fontSize={{ _: 20, sm: 26 }} mt={0} mb={48}>
                      {`We couldn't find a match for `}
                      <Text fontWeight="bold">{`"${valueTrimmed}."`}</Text>
                      {
                        ' Try another keyword, or see what our members are learning.'
                      }
                    </Box>
                    <Menu border="none" variant="popover">
                      <PopularContent onTrackingClick={onTrackingClick} />
                    </Menu>
                  </>
                )}
              </>
            ) : (
              <Menu border="none" variant="popover">
                <PopularSearches
                  navigateToSearch={navigateToSearch}
                  onTrackingClick={onTrackingClick}
                />
                <PopularContent onTrackingClick={onTrackingClick} />
              </Menu>
            )}

            {!!searchAsYouTypeResults?.top.length && (
              <StrokeButton
                my={16}
                onClick={() => {
                  onTrackingClick('view_all_results', {
                    search_id: searchAsYouTypeResults.searchId,
                  });
                  navigateToSearch(value, searchAsYouTypeResults?.searchId);
                  closeSearch();
                }}
              >
                View all results
              </StrokeButton>
            )}
            <QuizAndHelpCenterLinks
              onTrackingClick={onTrackingClick}
              handleCloseDropdown={closeSearch}
            />
          </SuggestionContainer>
        </Box>
      </FocusTrap>
    </>
  );
};
