import { FillButton, TextButton } from '@codecademy/gamut';
import { ReactNode } from 'react';

import { formatUrlWithRedirect } from '../../GlobalHeader/urlHelpers';
import { AppHeaderCatalogDropdown } from '../AppHeaderElements/AppHeaderCatalogDropdown';
import { AppHeaderDropdown } from '../AppHeaderElements/AppHeaderDropdown';
import { AppHeaderLink } from '../AppHeaderElements/AppHeaderLink';
import { AppHeaderLogo } from '../AppHeaderElements/AppHeaderLogo';
import { AppHeaderResourcesDropdown } from '../AppHeaderElements/AppHeaderResourcesDropdown';
import { AppHeaderClickHandler, AppHeaderItem } from './types';

type AppHeaderItemToElementType = {
  action: AppHeaderClickHandler;
  item: AppHeaderItem;
  isAnon: boolean;
  isStandalone?: boolean;
  isTeams?: boolean;
  redirectParam?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  mobile?: boolean;
};

export const mapAppHeaderItemToElement = ({
  action,
  item,
  isAnon,
  isStandalone,
  isTeams,
  redirectParam,
  onKeyDown,
  mobile = false,
}: AppHeaderItemToElementType): ReactNode => {
  switch (item.type) {
    case 'logo':
      return <AppHeaderLogo action={action} item={item} />;
    case 'link':
      return <AppHeaderLink mx={0} action={action} item={item} />;
    case 'dropdown':
    case 'resources-simple-dropdown':
    case 'profile-dropdown':
      return (
        <AppHeaderDropdown
          onKeyDown={onKeyDown}
          action={action}
          item={item}
          standalone={isStandalone}
        />
      );
    case 'catalog-dropdown':
      return (
        <AppHeaderCatalogDropdown action={action} item={item} isAnon={isAnon} />
      );
    case 'resources-dropdown':
      return (
        <AppHeaderResourcesDropdown
          action={action}
          item={item}
          isAnon={isAnon}
        />
      );

    case 'render-element':
      return item.renderElement();
    case 'text-button':
      return (
        <TextButton
          size={mobile ? 'small' : 'normal'}
          onClick={(event: React.MouseEvent) => action(event, item)}
          data-testid={item.dataTestId}
          href={
            item.redirect
              ? formatUrlWithRedirect(item.href, redirectParam)
              : item.href
          }
          variant={isTeams ? 'primary' : 'interface'}
        >
          {item.text}
        </TextButton>
      );
    case 'fill-button':
      return (
        <FillButton
          size={mobile ? 'small' : 'normal'}
          data-testid={item.dataTestId}
          href={
            item.redirect
              ? formatUrlWithRedirect(item.href, redirectParam)
              : item.href
          }
          onClick={(event: React.MouseEvent) => action(event, item)}
          variant={item.variant || 'interface'}
          icon={item.icon}
          iconPosition={item.iconPosition}
        >
          {item.text}
        </FillButton>
      );
  }
};
