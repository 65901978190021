import { UserType } from '@mono/data-codegen/monolith';

import { Banner, BannerAudienceType, BrandedBannerUser } from '../../types';

export const shouldShowForUser = (
  audiences: NonNullable<Banner['audiences']>,
  user: BrandedBannerUser
) => {
  if (
    audiences.includes(BannerAudienceType.Anonymous) &&
    user.type === UserType.Anonymous
  ) {
    return true;
  }
  if (
    audiences.includes(BannerAudienceType.Free) &&
    user.type === UserType.Free
  ) {
    return true;
  }
  if (
    audiences.includes(BannerAudienceType.Trial) &&
    user.type === UserType.Trial
  ) {
    return true;
  }
  if (
    audiences.includes(BannerAudienceType.Pro) &&
    user.pro &&
    user.type !== UserType.Trial
  ) {
    return true;
  }
  if (
    audiences.includes(BannerAudienceType.Admin) &&
    (user.role === 'admin' || user.role === 'staff')
  ) {
    return true;
  }
  return false;
};
