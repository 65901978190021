import { Anchor, Box } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { ComponentProps, useCallback } from 'react';
import * as React from 'react';

import { GlobalFooterClickHandler } from './types';

function FooterLinkItemsDefaults({
  as = 'ul',
  pl = 0,
  mb = 0,
  ...props
}: React.ComponentProps<typeof Box>) {
  return <Box as={as} pl={pl} mb={mb} {...props} />;
}

export const FooterLinkItems = styled(FooterLinkItemsDefaults)`
  list-style-type: none;
`;

export const FooterLinkItem = styled.li`
  font-size: 0.875rem;
  margin: 0.5rem 0;

  ${theme.breakpoints.sm} {
    font-size: 1rem;
    margin: 0.25rem 0;
  }

  ${theme.breakpoints.md} {
    margin: 0.15rem 0;
  }
`;

export const FooterLinkItemWithAnchor: React.FC<
  ComponentProps<typeof Anchor> & {
    trackingTarget: string;
    footerOnClick: GlobalFooterClickHandler;
  }
> = ({ trackingTarget, footerOnClick, ...anchorProps }) => {
  const anchorOnClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      footerOnClick({ event, target: trackingTarget });
    },
    [footerOnClick, trackingTarget]
  );

  return (
    <FooterLinkItem>
      <Anchor {...anchorProps} onClick={anchorOnClick} />
    </FooterLinkItem>
  );
};
