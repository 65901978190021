import { isOnServer } from '@mono/util-env';

import { useUserSession } from '@mono/data-user';
import { getOptimizelyUser, OptimizelyUser } from './optimizely';

export const useOptimizelyUserAttributes = (
  optimizelyUser?: OptimizelyUser
) => {
  // Only fetch if we haven't sent the user attributes down through SSR
  const { data } = useUserSession({
    shouldFetch: !optimizelyUser && !isOnServer(),
  });

  const fetchedOptimizelyUser = getOptimizelyUser(data?.attributes);
  return fetchedOptimizelyUser || optimizelyUser;
};
