import { SearchAction, SearchWorker } from './types';

let workerPromise: Promise<SearchWorker>;

const initErr = 'Search worker not initialized';

export const searchWorker = {
  init() {
    if (!workerPromise) {
      workerPromise = (async () => {
        // lazy load worker
        const { createSearchWorker } = await import('./worker');
        return createSearchWorker();
      })();
    }
  },

  async autocomplete(query: string) {
    if (!workerPromise) throw new Error(initErr);
    return (await workerPromise)(SearchAction.Autocomplete, query);
  },

  async searchAsYouType(query: string) {
    if (!workerPromise) throw new Error(initErr);
    return (await workerPromise)(SearchAction.SearchAsYouType, query);
  },

  async homepageSearch(query: string) {
    if (!workerPromise) throw new Error(initErr);
    return (await workerPromise)(SearchAction.HomepageSearch, query);
  },
};
