import { IconButton } from '@codecademy/gamut';
import { GamutIconProps } from '@codecademy/gamut-icons';
import { MouseEvent } from 'react';

import { DropdownAnchor, DropdownIcon, StyledText } from '../../shared';
import { useAppHeaderDropdownContext } from '../AppHeaderDropdownProvider';

interface AppHeaderNavButtonBaseProps {
  buttonRef: React.RefObject<HTMLButtonElement>;
  handleOnClick: (event: React.MouseEvent) => void;
  isOpen: boolean;
  title?: string;
}
interface AppHeaderTextNavButtonProps extends AppHeaderNavButtonBaseProps {
  icon?: never;
  isIconOnly?: never;
}

interface AppHeaderIconNavButtonProps extends AppHeaderNavButtonBaseProps {
  icon: React.ComponentType<GamutIconProps>;
  isIconOnly?: boolean;
}

type AppHeaderNavButtonProps =
  | AppHeaderTextNavButtonProps
  | AppHeaderIconNavButtonProps;

export const AppHeaderDropdownNavButton: React.FC<AppHeaderNavButtonProps> = ({
  buttonRef,
  handleOnClick,
  icon,
  isOpen,
  title,
  isIconOnly,
}) => {
  const { firstItemRef } = useAppHeaderDropdownContext();

  const dropdownNavOnClick = (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    handleOnClick(e);

    if (!isOpen) {
      firstItemRef?.focus();
    }
  };

  const sharedProps = {
    'aria-expanded': isOpen,
    onClick: dropdownNavOnClick,
    ref: buttonRef,
  };

  if (icon && isIconOnly)
    return (
      <IconButton
        icon={icon}
        tip={title ?? 'Dropdown'}
        tipProps={{ alignment: 'bottom-center', placement: 'floating' }}
        {...sharedProps}
      />
    );

  return (
    <DropdownAnchor {...sharedProps} title={title} variant="interface">
      <StyledText
        fontWeight={isOpen ? 'bold' : 'normal'}
        textAlign="center"
        title={title}
      >
        {title}
      </StyledText>
      <DropdownIcon open={isOpen} size={12} />
    </DropdownAnchor>
  );
};
