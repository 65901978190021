import { Text } from '@codecademy/gamut';
import styled from '@emotion/styled';

const defaultFontSize = { sm: 16 } as const;
const defaultMarginTop = [24, , 0] as const;

function FooterHeadingDefaults({
  as = 'h2',
  variant = 'p-small',
  mb = 16,
  fontWeight = 'bold',
  fontSize = defaultFontSize,
  mt = defaultMarginTop,
  ...props
}: React.ComponentProps<typeof Text>) {
  return (
    <Text
      as={as}
      variant={variant}
      mb={mb}
      fontWeight={fontWeight}
      fontSize={fontSize}
      mt={mt}
      {...props}
    />
  );
}

export const FooterHeading = styled(FooterHeadingDefaults)``;
