import { theme } from '@codecademy/gamut-styles';
/**
 * Fides property ID for `www.codecademy.com`.
 */

const PROPERTY_ID = 'FDS-3RC5S1';

interface Fides {
  gtm: () => void;
  onFidesEvent: (event: string, data: unknown) => () => void;
}

export const initializeFides = () => {
  const script = document.createElement('script');
  script.setAttribute('async', 'true');
  script.setAttribute(
    'src',
    `https://skillsoft-codecademy.fides-cdn.ethyca.com/fides.js?property_id=${PROPERTY_ID}`
  );
  script.setAttribute('type', 'text/javascript');
  script.addEventListener('load', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fides = window.Fides as Fides;
    fides.gtm();

    const unsubscribe = fides.onFidesEvent(
      'FidesUIShown',
      (detail: { extraDetails: { servingComponent: string } }) => {
        if (detail.extraDetails.servingComponent === 'banner') {
          const banner = document.getElementById('fides-banner');
          if (banner) {
            banner.setAttribute('tabIndex', '-1');
          }
        }

        unsubscribe();
      }
    );
  });

  document.body.appendChild(script);

  const style = document.createElement('style');
  style.textContent = rawStyles;
  document.body.appendChild(style);
};

// For now, these three values duplicate theme colors from gamut-styles
// We don't want to take a full dependency on that package here...
const rawStyles = `
:root {
  /* Colors */

  --fides-overlay-primary-color: ${theme.colors.hyper};
  --fides-overlay-body-font-color: #484848;

  /* Buttons */

  --fides-overlay-primary-button-background-hover-color: ${theme.colors.hyper};

  /* Toggles */
  --fides-overlay-primary-active-color: var(--fides-overlay-primary-color);
  --fides-overlay-primary-active-disabled-color: #bda4f7;
  --fides-overlay-inactive-color: #cbd5e0;
  --fides-overlay-disabled-color: #e1e7ee;

  /* Everything else */
  --fides-overlay-button-border-radius: ${theme.borderRadii.md};
  --fides-overlay-font-family: inherit;
  --fides-overlay-font-size-body: 0.875rem !important;
  --fides-overlay-line-height: 1.375rem !important;
  --fides-overlay-padding: padding: 1rem !important;
  --fides-overlay-padding: 0.875rem 1rem !important;
}

.fides-banner, .fides-modal-container {
 -webkit-font-smoothing: auto;
}

div#fides-overlay {
  font-size: var(--fides-overlay-font-size-body);
  line-height: var(--fides-overlay-line-height);
  z-index: 1000;
  position: fixed;
  white-space: pre-line;
  font-family: inherit;
  line-height: calc(1em + 0.4rem);
  -webkit-font-smoothing: auto;
  z-index: 9948031 !important
}

#fides-modal-link {
  cursor: pointer;
  display: none;
}

#fides-modal .fides-notice-toggle-title:hover {
  background-color: ${theme.colors.white} !important
}

#fides-modal .fides-modal-content {
  background-color: ${theme.colors.white} !important;
  border:0;
  font-size: 14px;
  line-height: 1.4;
}

.fides-modal-description a:focus {
  outline: 1px solid ${theme.colors.black} !important;
}

.fides-notice-toggle-title a:focus {
  outline: 1px solid ${theme.colors.black} !important;
}

#fides-modal .fides-notice-toggle, #fides-modal  fides-notice-toggle-expanded {
  background-color: ${theme.colors.white} !important;
}

#fides-modal .fides-banner-button-secondary.fides-save-button {
  color: var(--fides-overlay-primary-button-background-color) !important;
}

#fides-modal .fides-modal-footer {
  background-color: ${theme.colors.white}
}

#fides-modal .fides-toggle-display {
  color: transparent;
  padding: 0;
  padding-block: 2px;
  --offset: 0.2em !important;
  --diameter: 1.2em !important;
  background-color: #828285
}

.fides-toggle .fides-toggle-input:checked + .fides-toggle-display {
  background-color: var(--fides-overlay-primary-active-color) !important;
}

#fides-modal .fides-divider {
  display: none !important
}

#fides-modal .fides-modal-title {
  text-align: left !important;
}

div#fides-banner-container {
  bottom: 0 !important;
  left: 0 !important;
}

div#fides-banner-container button.fides-banner-button {
  font-size: 16px !important;
}

div#fides-banner {
  line-height: var(--fides-overlay-line-height);
  color: var(--fides-overlay-body-font-color) !important;
  width: 100% !important;
  overflow-y: hidden;
  border: 0 !important;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2) !important;
  background-color: ${theme.colors.white};
  -webkit-font-smoothing: auto;
  bottom: 1px;
}

div#fides-banner:focus {
  outline: 1px solid ${theme.colors.black} !important
}

div#fides-banner-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1436px;
  margin: 0 auto;
  row-gap: 16px;
  row-gap: unset !important
}

/* Responsive banner */

div#fides-banner-description {
  margin-top: 0;
  margin-bottom: 10px;
}

div#fides-banner-description span {
  line-height: 1.375rem !important;
}

div#fides-banner-description > div {
  text-align: center;
}

div#fides-banner-description > div a {
  font-weight: bold;
}

div#fides-button-group {
  justify-content: center;
  gap: 2em;
  background-color: transparent;
}

#fides-banner div#fides-button-group {
  margin-top: 0.5em;
  display: flex;
  display: flex;
  justify-content: center;
  background-color: transparent;
  gap: 16px !important;
}

div#fides-button-group .fides-banner-secondary-actions > button {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 16px !important
  padding-top: 3px !important;
  margin-top: 0;
  border: 1px solid var(--fides-overlay-primary-color);
  border-radius: 2px;
  box-sizing: border-box;
}

#fides-banner .fides-acknowledge-button, #fides-banner .fides-manage-preferences-button {
  margin-right: 0px !important;
}

.fides-modal-footer div#fides-button-group {
  width: 100% !important
}

#fides-modal .fides-modal-button-group.fides-modal-primary-actions {
  margin-left: 0 !important;
}

.fides-gpc-label {
  display: none !important;
}

button.fides-banner-button.fides-banner-button-tertiary {
  background: none;
  border: none;
  padding: 0;
  color: var(--fides-overlay-link-font-color);
  text-decoration: underline;
  cursor: pointer;
  line-height: 2em;
}

/** Modal */

div.fides-modal-content {
  line-height: var(--fides-overlay-line-height);
  color: var(--fides-overlay-body-font-color) !important;
  box-sizing: border-box;
  padding: var(--fides-overlay-padding);
  border: 1px solid var(--fides-overlay-primary-color);
  background-color: var(--fides-overlay-background-color);
  border-radius: var(--fides-overlay-component-border-radius);
  max-height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fides-toggle .fides-toggle-display {
  --offset: 0.1em;
  --diameter: 1em;
  /** Because we have a "hidden" attr on this toggle element, some CSS libs customers use may include a global display: none on the hidden attr. To prevent our toggles from being hidden we use !important here **/
  display: inline-flex !important;
  align-items: center;
  justify-content: space-around;
  width: calc(var(--diameter) * 2 + var(--offset) * 2);
  height: calc(var(--diameter) + var(--offset) * 2);
  box-sizing: content-box;
  position: relative;
  border-radius: 100vw;
  background-color: var(--fides-overlay-inactive-color);
  transition: 250ms;
}

/* Checked/unchecked states */
.fides-toggle .fides-toggle-input:checked + .fides-toggle-display {
  background-color: var(--fides-overlay-primary-active-color);
}
.fides-toggle .fides-toggle-input:checked + .fides-toggle-display::before {
  transform: translate(100%, -50%);
}

/* Disabled state */
.fides-toggle .fides-toggle-input:disabled {
  cursor: not-allowed;
}
.fides-toggle .fides-toggle-input:disabled + .fides-toggle-display {
  background-color: var(--fides-overlay-disabled-color);
}
.fides-toggle .fides-toggle-input:disabled:checked + .fides-toggle-display {
  background-color: var(--fides-overlay-primary-active-disabled-color);
}

/* Focus ring when using keyboard */
.fides-toggle .fides-toggle-input:focus + .fides-toggle-display {
  /* Firefox only has Highlight, not -webkit-focus-ring-color */
  outline: 1px auto Highlight;
  outline: 1px auto -webkit-focus-ring-color;
}

/* Disclosure */

.fides-notice-toggle .fides-notice-toggle-title {
  border: 0;
}

#fides-modal .fides-modal-secondary-actions {
  display: none !important;
}

.fides-notice-toggle .fides-notice-toggle-trigger {
  padding-left: 4px;
  position: relative;
  left: -4px;
}

.fides-notice-toggle-trigger > .fides-flex-center.fides-justify-space-between {
  font-weight: bold
}

/* Tabs */

.fides-tab-button::focus-visible {
  outline: 1px auto ${theme.colors.black};
}

.fides-tab-button:focus:not(:focus-visible) {
  outline: 4px solid ${theme.colors.hyper} !important;
}

.fides-toggle-input.focus-visible + .fides-toggle-display,
.fides-banner-button.fides-banner-button-primary.focus-visible,
.fides-banner-button.fides-banner-button-secondary.focus-visible,
.fides-banner-button.fides-banner-button-tertiary.focus-visible {
  outline: 3px solid ${theme.colors.hyper} !important;
  outline-offset: 2px;
}

/* Table */

.fides-cookies-table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

.fides-cookies-table th {
  text-transform: uppercase;
}

.fides-cookies-table td, .fides-cookies-table th {
  border: 1px solid var(--fides-overlay-row-divider-color);
  padding-left: 1.1em;
  padding-right: 0.6em;
}

#fides-banner #fides-button-group button {
  font-weight: bold;
  padding: 10px 16px;
  margin-top: 0;
  border: 1px solid var(--fides-overlay-primary-color);
  border-radius: 2px;
}

#fides-banner #fides-button-group div:nth-child(2) > button:nth-child(1) {
  background-color: white;
  color: #3a10e5;
}

#fides-banner-button-tertiary {
  display: none;
}

#fides-banner-heading {
  display: none !important;
}

div#fides-banner-inner div#fides-button-group {
  flex-direction: row !important;
}

.fides-banner-description a:hover, .fides-modal-description a:hover {
    text-decoration: none;
}

#fides-banner-inner-container a.focus-visible {
  outline: 1px solid ${theme.colors.black} !important;
}

div#fides-banner-inner div#fides-button-group {
  padding-top: 0 !important
}

#fides-banner .fides-acknowledge-button, #fides-banner .fides-manage-preferences-button {
  width: auto !important
}

#fides-banner .fides-banner-button-group.fides-banner-primary-actions {
  align-items: flex-end !important;
  align-self: stretch !important
}

#fides-banner .fides-banner-button-group.fides-banner-secondary-actions {
  align-items: flex-start !important;
}

.fides-notice-toggle:focus-visible {
    outline: 1px solid ${theme.colors.black} !important;
}

@media (min-width: 37.5rem) {
  #fides-banner {
    padding: 0.875rem 1rem !important;
  }
}

${theme.breakpoints.sm} {
  div#fides-button-group .fides-banner-secondary-actions > button {
    padding: 4px 16px !important
  }

  div#fides-banner-inner {
   row-gap: 16px;
  }

  #fides-banner {
    padding: 0.875rem 1.25rem !important;
  }
}

@media only screen and (min-width: 1650px) {
  #fides-banner-inner {
    flex-direction: row !important;
    max-width: 1600px !important;
  }

  #fides-banner-inner-container {
    width: 72%;
  }

  #fides-banner div#fides-button-group {
    width: 28% !important;
  }

  div#fides-banner-description {
    margin-bottom: 0 !important;
  }
}`;
