import { Box, FillButton, FlexBox, Modal, Text } from '@codecademy/gamut';
import { CodeCelebration } from '@codecademy/gamut-illustrations';
import { LoggedInUser } from '@mono/data-user';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import {
  checkoutStatusKey,
  emailCopy,
  getSessionStorageItems,
  removeSessionStorageItems,
  setSessionStorageItems,
  subscriptionCopy,
  trialCopy,
} from './helpers';
import {
  trackCheckoutSuccessModalClose,
  trackCheckoutSuccessModalImpression,
} from './tracking';

export const CheckoutSuccessModal: React.FC<{
  user: LoggedInUser;
  pageName: string;
}> = ({ user: { email, isPlusUser }, pageName }) => {
  const { query } = useRouter();
  const location = useLocation();

  const [activeView, setActiveView] = useState<
    'trial' | 'subscription' | 'bootcamp' | 'none'
  >('none');

  const [trialInfo, setTrialInfo] = useState<{
    length: number;
    unit: string;
  }>({ length: 7, unit: 'day' });

  useEffect(() => {
    // stripe has limitation of using session storage as payments are done via redirects
    const initialView =
      sessionStorage.getItem(checkoutStatusKey) || query?.checkoutSuccessType;

    // remove url queries for stripe checkout experience
    if (
      query?.checkoutSuccessType &&
      (initialView === 'trial' ||
        initialView === 'subscription' ||
        initialView === 'bootcamp')
    ) {
      const queryParams = { ...query } as Record<string, string | undefined>;

      window.history.pushState({}, '', '/learn');

      if (initialView !== 'bootcamp') setSessionStorageItems(queryParams);
    }

    if (initialView === 'trial' || initialView === 'subscription') {
      const {
        planId,
        providerId,
        trialLength,
        trialUnit,
        termMonths,
        currency,
        subtotal,
        couponCode,
      } = getSessionStorageItems();

      if (planId && termMonths) {
        window.dataLayer ||= [];
        window.dataLayer.push({
          event: 'checkout_success',
          planId,
          providerId,
          // The old checkout page was passing this as a number, but session storage
          // can only store strings (per typescript), so we convert it here,
          // but just in case something goes sideways we include the original value.
          termMonths: parseInt(termMonths, 10) || termMonths,
          isTrialPlan: initialView === 'trial',
          currency,
          subtotal,
          couponCode,
        });
      }
      if (location?.pathname?.includes('cancel')) {
        setActiveView('none');
        removeSessionStorageItems();
      } else {
        setActiveView(initialView);
      }

      trackCheckoutSuccessModalImpression(pageName);

      setTrialInfo({ length: Number(trialLength), unit: trialUnit });
    } else if (initialView === 'bootcamp') {
      setActiveView('bootcamp');
    }
    // Avoid watch for query change as event just needs to be triggered when page is loaded for stripe and recurly purchases
  }, [pageName, query, location?.pathname]);

  const handleClose = () => {
    trackCheckoutSuccessModalClose(pageName);
    removeSessionStorageItems();
    setActiveView('none');
  };

  const renderTextCopy = () => {
    if (activeView === 'trial') return trialCopy(isPlusUser, trialInfo);
    if (activeView === 'bootcamp')
      return 'Your bootcamp registration is successful.';
    return subscriptionCopy(isPlusUser);
  };

  const renderEmailCopy = () => {
    if (activeView === 'subscription') {
      return (
        <Box mt={8}>
          {emailCopy} <Text fontWeight="bold">{email}</Text>
        </Box>
      );
    }
    if (activeView === 'bootcamp') {
      return (
        <Box mt={8}>You&rsquo;ll receive an email soon with more details!</Box>
      );
    }
    return null;
  };

  return (
    <Modal onRequestClose={handleClose} isOpen={activeView !== 'none'}>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        p={{ _: 16, md: 64 }}
        pt={48}
      >
        <CodeCelebration width={195} height="auto" aria-hidden />
        <Text variant="title-md" mt={24}>
          Congrats!
        </Text>
        <Text variant="title-sm" mt={8} textAlign="center">
          {renderTextCopy()}
        </Text>
        {renderEmailCopy()}
        <FillButton onClick={handleClose} mt={24}>
          Start learning
        </FillButton>
      </FlexBox>
    </Modal>
  );
};
