import { GlobalFooterProps } from '@mono/brand';
import { userIsLoggedIn, useUser } from '@mono/data-user';

import { trackUserClick } from '~/libs/tracking';
import { useEnterpriseUser } from '~/libs/useEnterpriseUser';

export const useFooterProps = (
  pwaMode: boolean,
  hideFooter: boolean
): GlobalFooterProps | undefined => {
  const user = useUser();
  const enterpriseUser = useEnterpriseUser(user);

  if (hideFooter || enterpriseUser) return undefined;

  return {
    hidePricing: pwaMode,
    onClick: ({ target }) => {
      trackUserClick({ context: 'global_footer', target });
    },
    userGeo: user?.location.geo_country,
    referralRockUrls: {
      stats: process.env.NEXT_PUBLIC_REFERRALROCK_STATS_URL ?? '',
      tos: process.env.NEXT_PUBLIC_REFERRALROCK_TOS_URL ?? '',
    },
    isBusinessUser: user?.isBusinessUser,
    isLoggedIn: !!(user && userIsLoggedIn(user)),
  };
};
