import { logError } from '@mono/data-logging';
import { UserProfile } from '@optimizely/optimizely-sdk';
import { GetServerSidePropsContext } from 'next';

import envsInternalExternal from './envsInternalExternal';

import { cfAccessHeaders } from '@mono/data-cf-access-headers';

export const userProfileServiceLookup = async (
  userId: string,
  context?: GetServerSidePropsContext
) => {
  try {
    const res = await fetch(
      `${envsInternalExternal.MONOLITH_URL}/optimizely/lookup?user_id=${userId}`,
      {
        headers: {
          cookie: context?.req?.headers?.cookie || '', // server side
          Credentials: 'include', // client side
          ...cfAccessHeaders,
        },
      }
    );

    if (!res.ok) {
      logError(
        new Error(
          `Failed to fetch optimizely user profile from user profile service lookup ${res.status}: ${res.statusText}`
        )
      );
      return undefined;
    }

    const data = (await res.json()) as UserProfile | null;
    return data;
  } catch (error) {
    logError(error);
    return undefined;
  }
};

export const userProfileServiceSave = async (
  userProfile: UserProfile,
  context?: GetServerSidePropsContext
) => {
  const response = await fetch(
    `${envsInternalExternal.MONOLITH_URL}/optimizely/save`,
    {
      method: 'POST',
      headers: {
        cookie: context?.req?.headers?.cookie || '', // server side
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Credentials: 'include', // client side
        ...cfAccessHeaders,
      },
      body: JSON.stringify({
        user_profile: userProfile,
      }),
    }
  );

  if (!response.ok) {
    logError(
      new Error(
        `Failed to save user profile to optimizely user profile service ${response.status}: ${response.statusText}`
      )
    );
  }
};
