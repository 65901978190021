import { CourseDifficulty } from '../../../ContentGroupBaseCard/types';

export type HighlightSegment = {
  value: string;
  highlight: boolean;
};

export type AutocompleteSuggestion = {
  title: string;
  segments: HighlightSegment[];
};

interface SearchResults {
  query: string;
  queryLoadTime: number;
  searchId: string;
  resultsCount: number;
}

interface BaseTopData {
  type: string;
  title: string;
  slug: string;
}

export interface SearchAsYouTypeResults extends SearchResults {
  top: (BaseTopData & {
    contentId?: string;
    segments: HighlightSegment[];
    urlPath: string;
    key: string;
  })[];
}

export interface HomepageSearchResults extends SearchResults {
  top: (BaseTopData & {
    id: string;
    shortDescription: string;
    difficulty: CourseDifficulty;
    pro: boolean;
    goal: string | null;
    lessonCount: number;
    medianDurationHours: number;
    courseCount: number | null;
  })[];
}

export enum SearchAction {
  Autocomplete = 'autocomplete',
  SearchAsYouType = 'search-as-you-type',
  HomepageSearch = 'homepage-search',
}

export type SearchResultTypes = {
  [SearchAction.Autocomplete]: AutocompleteSuggestion[];
  [SearchAction.SearchAsYouType]: SearchAsYouTypeResults;
  [SearchAction.HomepageSearch]: HomepageSearchResults;
};

export type SearchWorker = <A extends SearchAction>(
  action: A,
  query: string
) => Promise<SearchResultTypes[A]>;
