import { Anchor, Box, BoxProps, Column, LayoutGrid } from '@codecademy/gamut';
import { theme, themed, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import * as React from 'react';

import { FooterHeading } from '../FooterHeading';
import { FooterLinkItem, FooterLinkItems } from '../FooterLinks';
import { GlobalFooterClickHandler } from '../types';
import downloadOnTheAppStore from './assets/download-on-the-app-store.svg';
import getItOnGooglePlay from './assets/get-it-on-google-play.png';

const defaultWidth = {
  _: '50%',
  md: '90%',
};

function MobileImageLinkDefaults({
  display = 'inline-block',
  variant = 'interface',
  width = defaultWidth,
  ...props
}: React.ComponentProps<typeof Anchor>) {
  return (
    <Anchor width={width} display={display} variant={variant} {...props} />
  );
}

function MobileImageItemDefaults({
  as = 'li',
  display = 'inline-block',
  my = 8,
  ...props
}: React.ComponentProps<typeof Box>) {
  return <Box as={as} display={display} my={my} {...props} />;
}

const MobileImageItem = styled(MobileImageItemDefaults)();

const MobileImageLink = styled(MobileImageLinkDefaults)();

const CatalogLinksContainer = styled.div`
  border-top: 1px solid ${themed('colors.navy')};
  margin-top: 2rem;

  ${theme.breakpoints.sm} {
    padding-top: 2rem;
  }

  ${theme.breakpoints.md} {
    border-left: 1px solid ${({ theme }) => theme.colors.navy};
    border-top: none;
    margin-left: 0;
    margin-top: 0;
    padding-left: 2rem;
    padding-top: 0;
  }

  ${theme.breakpoints.lg} {
    padding-left: 4rem;
  }
`;

const CatalogLinkArea = styled(FooterLinkItems)<{ variant?: 'fullHeight' }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  ${variant({ variants: { fullHeight: { maxHeight: { md: 'none' } } } })}
`;

const CatalogFooterLinkItem = styled(FooterLinkItem)`
  margin: 0.25rem 0;
`;

const languages = [
  ['bash', 'Bash'],
  ['c', 'C'],
  ['c-plus-plus', 'C++'],
  ['c-sharp', 'C#'],
  ['go', 'Go'],
  ['html-css', 'HTML & CSS'],
  ['java', 'Java'],
  ['javascript', 'JavaScript'],
  ['kotlin', 'Kotlin'],
  ['php', 'PHP'],
  ['python', 'Python'],
  ['r', 'R'],
  ['ruby', 'Ruby'],
  ['sql', 'SQL'],
  ['swift', 'Swift'],
];

const subjects = [
  ['artificial-intelligence', 'AI'],
  ['cloud-computing', 'Cloud computing'],
  ['code-foundations', 'Code foundations'],
  ['computer-science', 'Computer science'],
  ['cybersecurity', 'Cybersecurity'],
  ['data-analytics', 'Data analytics'],
  ['data-science', 'Data science'],
  ['data-visualization', 'Data visualization'],
  ['developer-tools', 'Developer tools'],
  ['devops', 'DevOps'],
  ['game-development', 'Game development'],
  ['information-technology', 'IT'],
  ['machine-learning', 'Machine learning'],
  ['math', 'Math'],
  ['mobile-development', 'Mobile development'],
  ['web-design', 'Web design'],
  ['web-development', 'Web development'],
];

export type CatalogLinksProps = {
  onClick: GlobalFooterClickHandler;
  userGeo?: string;
};

export const CatalogLinks: React.FC<CatalogLinksProps> = ({
  onClick,
  userGeo,
}) => {
  const languagesList = (
    <Box>
      <FooterHeading>Languages</FooterHeading>
      <CatalogLinkArea variant="fullHeight">
        {languages.map(([slug, text]) => (
          <CatalogFooterLinkItem key={slug}>
            <Anchor
              href={`/catalog/language/${slug}`}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                onClick({ event, target: slug })
              }
              variant="interface"
              whiteSpace="nowrap"
            >
              {text}
            </Anchor>
          </CatalogFooterLinkItem>
        ))}
      </CatalogLinkArea>
    </Box>
  );

  const subjectsList = (
    <Box>
      <FooterHeading>Subjects</FooterHeading>
      <CatalogLinkArea variant="fullHeight">
        {subjects.map(([slug, text]) => (
          <CatalogFooterLinkItem key={slug}>
            <Anchor
              href={`/catalog/subject/${slug}`}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                onClick({ event, target: slug })
              }
              variant="interface"
              whiteSpace="nowrap"
            >
              {text}
            </Anchor>
          </CatalogFooterLinkItem>
        ))}
      </CatalogLinkArea>
    </Box>
  );

  const careerBuildingList = (
    <Box>
      <FooterHeading>Career building</FooterHeading>
      <CatalogLinkArea variant="fullHeight">
        <CatalogFooterLinkItem>
          <Anchor
            href="/catalog/all"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'catalog' })
            }
            variant="interface"
            whiteSpace="nowrap"
          >
            Career paths
          </Anchor>
        </CatalogFooterLinkItem>
        <CatalogFooterLinkItem>
          <Anchor
            href="/career-center"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'career_services' })
            }
            variant="interface"
            whiteSpace="nowrap"
          >
            Career Center
          </Anchor>
        </CatalogFooterLinkItem>
        <CatalogFooterLinkItem>
          <Anchor
            href="/pages/interview-prep"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'interview_prep' })
            }
            variant="interface"
            whiteSpace="nowrap"
          >
            Interview prep
          </Anchor>
        </CatalogFooterLinkItem>
        <CatalogFooterLinkItem>
          <Anchor
            href="/pages/pro-certifications"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'pro_certifications' })
            }
            variant="interface"
            whiteSpace="nowrap"
          >
            Professional certification
          </Anchor>
        </CatalogFooterLinkItem>
        {userGeo !== 'IN' && (
          <CatalogFooterLinkItem>
            <Anchor
              href="https://try.codecademy.com/bootcamps"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                onClick({ event, target: 'bootcamps' })
              }
              variant="interface"
            >
              Compare to bootcamps
            </Anchor>
          </CatalogFooterLinkItem>
        )}
        <CatalogFooterLinkItem aria-hidden>—</CatalogFooterLinkItem>
        <CatalogFooterLinkItem>
          <Anchor
            href="/catalog/all"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'fullCatalog' })
            }
            variant="interface"
          >
            Full catalog
          </Anchor>
        </CatalogFooterLinkItem>
        <CatalogFooterLinkItem>
          <Anchor
            href="/catalog/subject/beta"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'betaContent' })
            }
            variant="interface"
          >
            Beta content
          </Anchor>
        </CatalogFooterLinkItem>
        <CatalogFooterLinkItem>
          <Anchor
            href="https://trello.com/b/vAgDXtT6/codecademy-releases-roadmap"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'roadmap' })
            }
            variant="interface"
          >
            Roadmap
          </Anchor>
        </CatalogFooterLinkItem>
      </CatalogLinkArea>
    </Box>
  );

  const appStoreLinks = (display: BoxProps['display']) => (
    <Box display={display}>
      <FooterHeading>Mobile</FooterHeading>
      <FooterLinkItems display="flex" flexDirection="column">
        <MobileImageItem>
          <MobileImageLink
            href="https://itunes.apple.com/us/app/codecademy-go/id1376029326"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'apple_store' })
            }
            target="_blank"
            rel="noopener"
          >
            <img
              alt="Download on the App Store"
              height="calc(40px + 1rem)"
              src={downloadOnTheAppStore}
              width="calc(120px + 1.5rem)"
            />
          </MobileImageLink>
        </MobileImageItem>
        <MobileImageItem>
          <MobileImageLink
            href="https://play.google.com/store/apps/details?id=com.ryzac.codecademygo"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              onClick({ event, target: 'google_play' })
            }
            target="_blank"
            rel="noopener"
          >
            <img
              alt="Get it on Google Play"
              height="calc(40px + 1rem)"
              src={getItOnGooglePlay}
              width="calc(133px + 1.5rem)"
            />
          </MobileImageLink>
        </MobileImageItem>
      </FooterLinkItems>
    </Box>
  );

  // Footer items change placement depending on screen size
  return (
    <CatalogLinksContainer>
      <LayoutGrid columnGap={24}>
        <Column size={{ _: 6, sm: 4 }}>{subjectsList}</Column>
        <Column size={{ _: 6, sm: 4 }}>{languagesList}</Column>
        <Column size={{ _: 6, sm: 4 }} gridTemplateRows="min-content">
          {careerBuildingList}
          {/* desktop/tablet */}
          {appStoreLinks({ _: 'none', sm: 'unset' })}
        </Column>
        <Column size={6} display={{ _: 'block', sm: 'none' }}>
          {/* mobile */}
          {appStoreLinks({ _: 'unset', sm: 'none' })}
        </Column>
      </LayoutGrid>
    </CatalogLinksContainer>
  );
};
