import { fetchCacher } from '@mono/data-fetch-cachers';
import { logError } from '@mono/data-logging';
import { isOnServer } from '@mono/util-env';
import {
  Config,
  enums,
  logging,
  UserProfile,
} from '@optimizely/optimizely-sdk';
import { createInstance, ReactSDKClient } from '@optimizely/react-sdk';

import envsInternalExternal from './envsInternalExternal';

import { cfAccessHeaders } from '@mono/data-cf-access-headers';

import { Attributes, fetchUserSession } from '@mono/data-user';
import { createOnActivate } from './experimentAssignmentEvent';
import {
  userProfileServiceLookup,
  userProfileServiceSave,
} from './userProfileService';
import { GetServerSidePropsContextWithAdditions } from './typings/mockGetServerSidePropsWithAdditionalContext';

const SDK_KEY = process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY;

export type Datafile = NonNullable<Config['datafile']>;

export type OptimizelyUser = {
  id: Attributes['id'];
  attributes: Attributes;
};

const OPTIMIZELY_DATAFILE_URL =
  process.env.NODE_ENV !== 'production'
    ? `https://cdn.optimizely.com/datafiles/${SDK_KEY}.json`
    : `${envsInternalExternal.MONOLITH_URL}/optimizely/datafile`;

const datafileInit: RequestInit = OPTIMIZELY_DATAFILE_URL.includes(
  'codecademy.com'
)
  ? {
      headers: {
        Credentials: 'include',
        ...cfAccessHeaders,
      },
    }
  : {};

const datafileFetcher = async (): Promise<Datafile> => {
  const response = await fetch(OPTIMIZELY_DATAFILE_URL, datafileInit);

  if (!response.ok) {
    throw new Error(
      `Could not fetch Optimizely datafile ${response.status}: ${response.statusText}`
    );
  }

  return (await response.json()) as Datafile;
};

export const getOptimizelyDatafile = () =>
  fetchCacher('optimizelyDatafile', datafileFetcher, {
    revalidateMs: 300000, // 5 minutes
  }).catch(() => undefined);

export const getOptimizelyClient = (
  datafile?: Datafile,
  context?: GetServerSidePropsContextWithAdditions,
  showLogsOnProd = false
) => {
  /**
   * This config object is used to create an optimizely client both client side and server side.
   * If you are changing or adding fields in the config, please carefully read both the React SDK and Node SDK documentation
   * as many of the settings have different default values and/or behave differently on the client and server.
   * Node SDK: https://docs.developers.optimizely.com/experimentation/v3.1.0-full-stack/docs/initialize-sdk-javascript-node
   * React SDK: https://docs.developers.optimizely.com/experimentation/v3.1.0-full-stack/docs/initialize-sdk-react
   */
  const config: Config = {
    /**
     * in order to SSR, we need to manually pass the datafile and can't have the client fetch it with the sdk key
     * https://github.com/optimizely/react-sdk#server-side-rendering
     * client side we can instantiate with just the datafile, since we don't have access to the sdk key
     * https://docs.developers.optimizely.com/experimentation/v3.1.0-full-stack/docs/initialize-sdk-react#instantiate-using-datafile
     * In the rare event that we need to instantiate on the client without calling this first in ssr, we do include the key.
     */
    ...(datafile ? {} : { sdkKey: SDK_KEY }),
    datafile,
    datafileOptions: {
      urlTemplate: OPTIMIZELY_DATAFILE_URL,
      // disabling since we are using fetch cacher to cache the datafile requests (server side the default is true)
      autoUpdate: false,
    },
    userProfileService: {
      /**
       * since the lookup calls are async we are not implementing the lookup method
       * and instead adding $opt_experiment_bucket_map within user attributes with the same data
       * https://docs.developers.optimizely.com/experimentation/v3.1.0-full-stack/docs/implement-a-user-profile-service-react#implement-asynchronous-user-lookups-with-experiment-bucket-map-attribute
       */
      lookup: () => ({} as unknown as UserProfile),
      save: (userProfile) => userProfileServiceSave(userProfile, context),
    },
    errorHandler: {
      handleError: (error) => {
        logError(error);
      },
    },
    // server side the logger has to be manually created before we can set the log level
    // https://docs.developers.optimizely.com/experimentation/v3.1.0-full-stack/docs/customize-logger-javascript-node
    logger: logging.createLogger(),
    logLevel:
      // on prod, we only want errors logged (they also go to the errorHandler).
      // on other envs, debug and above logs are helpful.
      process.env.NEXT_PUBLIC_ENV === 'production' && !showLogsOnProd
        ? enums.LOG_LEVEL.ERROR
        : enums.LOG_LEVEL.DEBUG,
    /**
     * disabling event batching server side since a new client gets initialized on every page that opts in
     * (1 is disabled, undefined uses the default value)
     * https://docs.developers.optimizely.com/experimentation/v3.1.0-full-stack/docs/event-batching-react
     */
    eventBatchSize: isOnServer() ? 1 : 10,
    eventFlushInterval: 30000,
  };

  const optimizelyClient = createInstance(config);

  // Subscribe to the activate event to trigger our analytics
  optimizelyClient.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.ACTIVATE,
    createOnActivate(context)
  );

  return validateUserForExperiment(optimizelyClient);
};

// Users marked with the `e2e_test_user` attribute and `pii_safe` = false
// should never be put in experiments. This is enforced by overriding
// the Optimizely `activate` function to no-op for users with this user attribute.
const validateUserForExperiment = (
  optimizelyClient: ReactSDKClient
): ReactSDKClient => {
  let isE2EUser = Boolean(optimizelyClient.user.attributes?.e2e_test_user);
  let isPiiSafe = Boolean(optimizelyClient.user.attributes?.pii_safe);
  optimizelyClient.onUserUpdate((userInfo) => {
    isE2EUser = Boolean(userInfo.attributes?.e2e_test_user);
    isPiiSafe = Boolean(userInfo.attributes?.pii_safe);
  });

  const optimizelyClientWrapper: ReactSDKClient = optimizelyClient;

  const originalActivate = optimizelyClient.activate.bind(optimizelyClient);
  optimizelyClientWrapper.activate = (...args) =>
    isE2EUser || !isPiiSafe ? null : originalActivate(...args);

  return optimizelyClientWrapper;
};

export const getOptimizelyUser = (
  userAttributes?: Attributes
): OptimizelyUser | undefined => {
  if (!userAttributes) return;
  const { id } = userAttributes;

  return {
    id,
    attributes: userAttributes,
  };
};

const appendAttributesFromQuery = (
  context: GetServerSidePropsContextWithAdditions,
  userAttributes?: Attributes
) => {
  if (!userAttributes) return userAttributes;

  const { utm_medium: query_utm_medium } = context.query;

  const utm_medium = Array.isArray(query_utm_medium)
    ? query_utm_medium[query_utm_medium.length - 1] || ''
    : query_utm_medium || '';

  return {
    ...userAttributes,
    utm_medium,
  };
};

export const setOptimizelyUser = async (
  optimizely: ReactSDKClient,
  context: GetServerSidePropsContextWithAdditions,
  userAttributes?: Attributes
) => {
  const attributes = appendAttributesFromQuery(context, userAttributes);

  const optimizelyUser = getOptimizelyUser(attributes);

  // set the current user's data in the Optimizely SDK client
  if (optimizelyUser) {
    optimizely.setUser(optimizelyUser);
  }
};

export const fetchOptimizely = async (
  context: GetServerSidePropsContextWithAdditions
) => {
  // Short-circuit if the data has already been added to the context.
  if (context.optimizely) {
    return context.optimizely;
  }

  const [userSessionData, datafile] = await Promise.all([
    fetchUserSession(context),
    getOptimizelyDatafile(),
  ]);

  // initialize the Optimizely SDK client
  const optimizely = getOptimizelyClient(datafile, context);

  await setOptimizelyUser(optimizely, context, userSessionData?.attributes);

  try {
    await optimizely.onReady();
  } catch (error) {
    logError(error);
  }

  return optimizely;
};

// This makes optimizely available inside `getServerSideProps`
// without have to make an extra fetch (since the data is already fetched in `getServerSidePropsWrapper`).
export const fetchAndAddOptimizelyToContext = async (
  context: GetServerSidePropsContextWithAdditions
) => {
  const optimizely = await fetchOptimizely(context);

  context.optimizely = optimizely;
};

// update optimizely user
export const updateAndSetOptimizelyUser = async (
  optimizely: ReactSDKClient,
  context: GetServerSidePropsContextWithAdditions
) => {
  const { user } = optimizely;

  // look up the user's profile (previous experiment assignments) in the user profile service
  const userProfile = user.id
    ? await userProfileServiceLookup(user.id, context)
    : undefined;

  // override $opt_experment_bucket_map from newest user profile
  const attributes = userProfile
    ? {
        ...user.attributes,
        $opt_experiment_bucket_map: userProfile.experiment_bucket_map,
      }
    : user.attributes;

  await setOptimizelyUser(
    optimizely,
    context,
    attributes as Attributes | undefined
  );
};
