import { getFullURLFromPath } from './getFullURLFromPath';
import { getUrl } from './getUrl';

export const slugOrBlank = (slug: string | undefined) =>
  slug ? `/${slug}` : '';

export const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_PATH;

/* ABOUT */
export const getAboutCareersPath = () => `/about/careers`;

export const getJobPath = (id: number) => `/about/job/${id}`;

/* ACCOUNT + BILLING */
export const getAccountSettingsPath = () => '/account';

export const getGoalSettingsPath = () =>
  `${getAccountSettingsPath()}/goals_settings`;

export interface BillingPathParams {
  showModal?: boolean;
  changePlan?: boolean;
  redirectUrl?: string;
  renewedPlan?: string;
}

export const getBillingPath = (params?: BillingPathParams) => {
  const billingPath = getUrl(`${getAccountSettingsPath()}/billing`, {
    showModal: params?.showModal,
    changePlan: params?.changePlan,
    redirect_url: params?.redirectUrl,
    renewedPlan: params?.renewedPlan,
  });

  return billingPath;
};

/* ARTICLES */
type ArticlesPathParams = {
  section?: string;
  slug?: string;
};

export const getArticlesPath = ({ section, slug }: ArticlesPathParams = {}) =>
  `/articles${slugOrBlank(section)}${slugOrBlank(slug)}`;

export const getArticlePath = (slug: string) => `/article/${slug}`;

/* ASSETS */
export const getAssetUrl = (path: string) =>
  `https://static-assets.codecademy.com/assets/${path}`;

export const getPathImageUrl = (pathSlug: string) =>
  `https://static-assets.codecademy.com/components/curriculum/path/${pathSlug}/curriculum-card.svg`;

export const getCertificationProviderLogoUrl = (fileName: string) =>
  `${getAssetUrl('certification-provider-logos')}/v1/${fileName}`;

export const getCertificationPrepHubHeroImageUrl = (fileName: string) =>
  getAssetUrl(`certification-prep-hub/v1/${fileName}`);

/* BLOG */
export const getFullBlogUrl = (slug: string) =>
  getFullURLFromPath(`/resources/blog/${slug}`);

export const getLearnerStoriesPath = () =>
  '/resources/blog/category/learner-stories/';

/* BOOTCAMP */
export const getBootcampContentItemPath = (
  bootcampSlug: string,
  contentItem: ContentItem,
  queryParams?: Record<string, string | boolean>
) => {
  if (!contentItem.slug || !contentItem.hasContentItemAccess) return '';

  const bootcampBasePath = `/bootcamps/${bootcampSlug}`;
  let path = '';

  switch (contentItem.type) {
    case 'project':
      path = `${getProjectHubHomePath()}/practice/${contentItem.slug}`;
      break;
    case 'replay':
      path = `${bootcampBasePath}/recordings/${contentItem.slug}`;
      break;

    case 'live_session':
      path = `${bootcampBasePath}/sessions/${contentItem.slug}`;
      break;

    default:
      path = `${bootcampBasePath}/${contentItem.type}s/${contentItem.slug}`;
      break;
  }

  return queryParams ? getUrl(path, queryParams) : path;
};

/* BUSINESS */
export enum BusinessCheckoutPlanTypeEnum {
  Trial = 'trial',
  Upgrade = 'upgrade',
  DirectPurchase = 'directpurchase',
}

export enum BusinessCheckoutPayOptionEnum {
  Now = 'now',
  Later = 'later',
}

export const getBusinessCheckoutSuccessPath = (
  planType: string,
  payOption?: BusinessCheckoutPayOptionEnum
) => {
  return `/business/checkout/success?plan_type=${planType}&pay_option=${payOption}`;
};

export const getBusinessCheckoutActivatePath = (
  planType: string,
  payOption?: BusinessCheckoutPayOptionEnum
) => {
  return `/business/checkout/success/activate?plan_type=${planType}&pay_option=${payOption}`;
};

export const getBusinessPathLandingPagePath = (pathSlug: string) =>
  `/business/paths/${pathSlug}`;

export const getTeamsCheckoutPath = (
  planType: 'directpurchase' | 'upgrade' | 'trial'
) => `/business/checkout?plan_type=${planType}`;

export const getTeamsDashboardPath = (id: string) =>
  `/business/account/${id}/teams`;

export const getTeamsQuote = () => '/business/teams-quote';

/* CAREER JOURNEY */
export const getCareerJourneyCurriculumPath = ({
  careerJourneySlug,
}: {
  careerJourneySlug: string;
}) => `/career-journey/${careerJourneySlug}`;

export const getCareerJourneyTrackSyllabusPath = ({
  careerJourneySlug,
  pathSlug,
  trackSlug,
}: {
  careerJourneySlug: string;
  pathSlug: string;
  trackSlug: string;
}) =>
  `${getCareerJourneyCurriculumPath({
    careerJourneySlug,
  })}/path/${pathSlug}/track/${trackSlug}`;

interface SyllabusContentItemPathParams {
  journeySlug: string;
  pathSlug: string;
  trackSlug: string;
  moduleSlug: string;
  contentItemSlug: string;
  contentItemType: string;
}
const getContentItemTypeRoutePart = (contentItemType: string) =>
  contentItemType === 'quiz' ? 'quizzes' : `${contentItemType}s`;

export const getSyllabusContentItemPath = ({
  journeySlug,
  pathSlug,
  trackSlug,
  moduleSlug,
  contentItemSlug,
  contentItemType,
}: SyllabusContentItemPathParams) => {
  const contentItemTypeRoute = getContentItemTypeRoutePart(contentItemType);
  return `/journeys/${journeySlug}/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}/${contentItemTypeRoute}/${contentItemSlug}`;
};

export const getSectionAssessmentPath = ({
  journeySlug,
  pathSlug,
  partNumber,
}: {
  journeySlug: string;
  pathSlug: string;
  partNumber: string;
}) => {
  return `/exams/journeys/${journeySlug}/paths/${pathSlug}/parts/${partNumber}`;
};

export const getSmartPracticePath = ({
  slug,
  contentType,
  source = 'syllabus',
}: {
  slug: string;
  contentType: string;
  source?: string;
}) => `/smart-practice/${contentType}s/${slug}?source=${source}`;

export const getModulePracticePath = ({
  trackSlug,
  moduleSlug,
  source = 'syllabus',
}: {
  trackSlug: string;
  moduleSlug: string;
  source?: string;
}) => `/practice/tracks/${trackSlug}/modules/${moduleSlug}?source=${source}`;

/* CATALOG */
export const getCatalogBasePath = () => '/catalog';

export const getCatalogPath = ({
  categoryType,
  category,
}: {
  categoryType: string;
  category?: string;
}) =>
  `${getCatalogBasePath()}${slugOrBlank(categoryType)}${slugOrBlank(category)}`;

/* CIRCLE */
export const getCircleStartHerePath = () =>
  'https://community.codecademy.com/c/start-here/';

/* CODE CHALLENGES */
export const getCodeChallengePath = (slug: string) =>
  `/code-challenges/${slug}`;

/* CONTAINER LANDING PAGES (UNENROLLED SYLLABUS) */
export type ContainerLiteralType =
  | 'Path'
  | 'Track'
  | 'ExternalCourse'
  | 'ExternalPath';

export const getLearnPath = (
  slug: string,
  containerType: ContainerLiteralType
) => {
  let pathSegment = '';
  switch (containerType) {
    case 'Path':
      pathSegment = 'paths/';
      break;
    case 'ExternalCourse':
      pathSegment = 'ext-courses/';
      break;
    case 'ExternalPath':
      pathSegment = 'ext-paths/';
      break;
    default:
      pathSegment = '';
  }
  return `${getLearnBasePath()}/${pathSegment}${slug}`;
};

interface BaseContentItem {
  id: string;
  hasContentItemAccess: boolean;
  slug: string;
  title: string;
  type: string;
}
interface RawProjectContentItem extends BaseContentItem {
  description: string;
  tracks?: {
    slug: string;
  }[];
  categories?: {
    classification: string;
    slug: string;
  }[];
}

export type ContentItem = NonNullable<BaseContentItem | RawProjectContentItem>;

export const getContentItemPath = (
  trackSlug: string,
  contentItem: ContentItem,
  queryParams?: Record<string, string | boolean>
) => {
  const type = contentItem.type
    ? getContentItemTypeRoutePart(contentItem.type)
    : '';
  const path = `/courses/${trackSlug}/${type}/${contentItem.slug}`;

  return queryParams ? getUrl(path, queryParams) : path;
};

// Entry path in the LE
export const getPathStartPath = (pathSlug: string) => {
  return `/paths/${pathSlug}`;
};

/* CHEATSHEETS */
export const getCheatsheetPath = ({
  pathSlug,
  trackSlug,
  moduleSlug,
}: {
  pathSlug?: string;
  trackSlug: string;
  moduleSlug: string;
}) =>
  pathSlug
    ? `/learn/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}/cheatsheet`
    : `/learn/${trackSlug}/modules/${moduleSlug}/cheatsheet`;

export const getEmoduleCheatsheetPath = ({
  emoduleSlug,
}: {
  emoduleSlug: string;
}) => `/learn/emodules/${emoduleSlug}/cheatsheet`;

export const getCheatsheetsPath = (category?: {
  classification: string;
  slug: string;
}) =>
  `/resources/cheatsheets/${
    category ? `${category.classification}/${category.slug}` : 'all'
  }`;

export const getReviewCardPath = (id: string) =>
  `https://author.codecademy.com/review-cards/${id}`;

/* DOCS */
export type DocsParams =
  | {
      topic?: string;
      concept?: never;
      term?: never;
    }
  | {
      topic: string;
      concept: string;
      term?: never;
    }
  | {
      topic: string;
      concept: string;
      term: string;
    };

export const getDocsPath = ({
  topic,
  concept,
  term,
}: DocsParams = {}): string => {
  let path = '/resources/docs';
  if (topic) {
    path += `/${topic}`;
  }
  if (topic && concept) {
    path += `/${concept}`;
  }
  if (topic && concept && term) {
    path += `/${term}`;
  }
  return path;
};

export const normalizeDocsSlug = (slug: string) =>
  slug
    .split('/')
    .filter((string) => !['concepts', 'terms'].includes(string))
    .join('/');

export const getFullDocsBaseUrl = () => getBaseUrl() + getDocsPath() + '/';

export const getDocsContributionGuidePath = () =>
  `${getDocsPath()}/contribution-guide`;

export const getDocsContributionGuideTutorialPath = (tutorialTopic: string) =>
  `${getDocsContributionGuidePath()}/${tutorialTopic}`;

/* DASHBOARD */
export const getLearnBasePath = () => '/learn';

export const studyGroupLeaderboardPath = () => '/learn?page=study_group';

/* ENROLLED SYLLABUS */
export const getCumulativeAssessmentPath = (
  pathSlug: string,
  trackSlug: string
) => `/cumulative-assessment/paths/${pathSlug}/tracks/${trackSlug}`;

export const getEnrolledLearnPath = ({
  slug,
  isPath,
}: {
  slug: string;
  isPath?: boolean;
}) => `/enrolled/${isPath ? 'paths/' : 'courses/'}${slug}`;

export const getExternalCoursePath = (slug: string) => `/ext-courses/${slug}`;

export const getExternalPathPath = (slug: string) => `/ext-paths/${slug}`;

/* INTERVIEW SIMULATOR */
export const interviewSimulatorPath = () => '/interview-simulator';

export const interviewSimulatorChatPath = (params: URLSearchParams) =>
  `${interviewSimulatorPath()}/interview?${params}`;

export const interviewSimulatorFeedbackPath = (id: string) =>
  `${interviewSimulatorPath()}/interviews/${id}`;

/* JOB READINESS CHECKER */
export const getJobReadinessPath = () => '/job-readiness';

export const getJobReadinessJobPath = (id: string) =>
  `/job-readiness/job/${id}`;

/* LANDING PAGE BUILDER */
export const getLandingPageBuilderPath = (slug: string) => `/pages/${slug}`;

/* LEARNING OUTCOME */
export const getLearningOutcomesPath = (id: string) =>
  `/learning-outcomes/${id}`;

/* LOGIN + REGISTRATION */
export const getLoginPath = (params: { redirect?: string; error?: string }) =>
  getUrl('/login', params);

export const getRegisterPath = (params?: { redirect?: string }) =>
  getUrl('/register', params || {});

/* PAYMENTS */
// this is a monolith REST endpoint, so locally you'll need a local nginx config for this to work cross-system
// see: https://gist.github.com/ahmed-abdallah/6d90db9be3f260e79dc137423f3b310c
export const getPayPath = () => '/payments/pay';
export const getBusinessPayPath = () => '/business/direct_purchase';
export const getBusinessUpgradePath = (planId: string) =>
  `/business/plans/${planId}/checkout/convert_trial`;
export const getBusinessRenewPath = (planId: string) =>
  `/business/plans/${planId}/checkout/renew`;
export const getBusinessDirectPurchaseRaiseInvoicePath = () =>
  '/business/direct_purchase_quote';

export const getBusinessTrialConversionRaiseInvoicePath = (planId: string) =>
  `/business/plans/${planId}/checkout/convert_trial_quote`;
export const getBusinessRenewRaiseInvoicePath = (planId: string) =>
  `/business/plans/${planId}/checkout/renew_quote`;

export const getBusinessUpgradeQuotePath = () =>
  `/business/convert_trial_from_quote`;
export const getBusinessPlansPath = () => '/business/plans';

export const getWhatsappCheckoutCampaignPath = () =>
  '/whatsapp/checkout_campaign';

export const getWhatsappBootcampCheckoutCampaignPath = () =>
  '/whatsapp/bootcamp_checkout_campaign';

// Stripe monolith APIs
export const getStripePaymentIntent = () => '/payments/stripe/payment_method';
export const getStripePaymentCallback = () => '/payments/stripe/callback';

// One-time payment invoice APIs
export const getOnetimePayInvoicePath = (invoiceId: string) =>
  `/payments/onetime/pay/${invoiceId}`;

// Charge APIs
export const getChargeInitPath = () => '/payments/charge/init';
export const getChargeUpdatePath = () => '/payments/charge/update';

/* PROFILE */
export const getProfilePath = (username: string) => `/profiles/${username}`;

export const getCertificatePath = (username: string, containerId: string) =>
  `${getProfilePath(username)}/certificates/${containerId}`;

export const getProfileEditPath = (username: string) =>
  `${getProfilePath(username)}/edit`;

export const getMyProfilePath = () => getProfilePath('me');

export const getEmailConfirmationPath = (resend?: 'resend') =>
  getUrl('/confirm', { resend: resend === 'resend' || undefined });

/* PRICING */
export const getPricingPath = () => '/pricing';

/* PRO CHECKOUT */
const proCheckoutPath = ({
  // TODO: Should we default to getting planId from a globally accessible value?
  // https://codecademy.atlassian.net/browse/GA-92
  planId = 'proGoldAnnualV2',
  redirectUrl = '',
  fromSignUpPage,
  discountCode,
  leftHandSide,
  skipOnboarding,
  periods,
  loggedInViaCheckout,
  planType,
}: ProCheckoutPathParams) =>
  getUrl(`/subscriptions/${planId}/checkout`, {
    ...(redirectUrl !== '' && { redirect_url: redirectUrl }),
    fromSignUpPage,
    skipOnboarding,
    periods,
    discountCode,
    lhs: leftHandSide,
    logged_in_via_checkout: loggedInViaCheckout,
    plan_type: planType,
  });

export enum ProCheckoutPathPlanType {
  Pro = 'pro',
  Plus = 'plus',
}

type ProCheckoutPathParams = {
  isTrial?: boolean;
  planId?: string;
  redirectUrl?: string;
  discountCode?: string;
  fromSignUpPage?: boolean;
  leftHandSide?: string;
  skipOnboarding?: boolean;
  periods?: string;
  loggedInViaCheckout?: boolean;
  planType?: ProCheckoutPathPlanType;
};

export const getProCheckoutPath = ({
  isTrial,
  ...params
}: ProCheckoutPathParams) =>
  proCheckoutPath({
    ...params,
    // TODO: Should we default to getting planId from a globally accessible value?
    // https://codecademy.atlassian.net/browse/GA-92
    // userAttributes.payments('defaultTrialPlanId'),
    ...(isTrial && { planId: params.planId ?? 'proGoldAnnualV2_7trial' }),
  });

export const getPortalCheckoutPath = ({
  planId,
  redirectUrl = '',
  isTrial,
  loggedInViaCheckout,
  ...params
}: ProCheckoutPathParams) =>
  getUrl(`/checkout`, {
    planId,
    ...(redirectUrl !== '' && { redirect_url: redirectUrl }),
    ...(isTrial && { planId: planId ?? 'proGoldAnnualV2_7trial' }),
    logged_in_via_checkout: loggedInViaCheckout,
    ...params,
  });

/* PROJECTS */
export const getProjectHubHomePath = () => '/projects';

type ProjectHubCategoryType = 'language' | 'subject';
type ProjectSubcategoryType = 'practice' | 'portfolio';

export const getProjectHubCategoryPath = ({
  categoryType,
  categorySlug,
}: {
  categoryType: ProjectHubCategoryType;
  categorySlug: string;
}) => `${getProjectHubHomePath()}/${categoryType}/${categorySlug}`;

export const getProjectHubSubcategoryPath = ({
  categoryType,
  categorySlug,
  projectType,
}: {
  categoryType: ProjectHubCategoryType;
  categorySlug: string;
  projectType: ProjectSubcategoryType;
}) =>
  `${getProjectHubHomePath()}/${categoryType}/${categorySlug}/${projectType}`;

export const getProjectPath = ({
  projectType,
  projectSlug,
}: {
  projectType: ProjectSubcategoryType;
  projectSlug: string;
}) => `${getProjectHubHomePath()}/${projectType}/${projectSlug}`;

export const getCustomProjectPath = (id: string) => `/custom-project/${id}`;

/* QUIZ */
export const getSortingQuizPath = () => '/explore/sorting-quiz';

export const getWelcomeOnboarding = () => '/welcome/find-a-course';

export const getWelcomeOnboardingResults = () =>
  '/welcome/find-a-course/results';

/* WORKSPACES */
export const getWorkspacesPath = (id: string) => `/users/${id}/workspaces`;

/* VIDEOS */
export const getVideosPath = () => `/resources/videos`;

export const getVideoPlaylistPath = (playlistSlug: string) =>
  `${getVideosPath()}/${playlistSlug}`;

export const getVideoDetailPath = (playlistSlug: string, videoSlug: string) =>
  `${getVideoPlaylistPath(playlistSlug)}/${videoSlug}`;

export const getYoutubeVideoPath = (videoId: string) =>
  videoId && `https://www.youtube.com/watch?v=${videoId}`;
