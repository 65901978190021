import { cfAccessHeaders } from '@mono/data-cf-access-headers';
import { logAndReturnError, logError, logWarn } from '@mono/data-logging';
import { UserProfile } from '@optimizely/optimizely-sdk';
import { GetServerSidePropsContext } from 'next';
import requestIp from 'request-ip';
import useSWR from 'swr';

import envsInternalExternal from './envsInternalExternal';

export type Attributes = {
  active_subscription: boolean;
  admin: boolean;
  anonymous: boolean;
  country_code: string;
  env: string;
  e2e_test_user: boolean;
  forum_moderator: boolean;
  free: boolean;
  id: string;
  optimizely_test_user: boolean;
  pro: boolean;
  student: boolean;
  trial: boolean;
  utm_medium: string;
  enterprise: boolean;
  $opt_experiment_bucket_map: UserProfile['experiment_bucket_map'];
};

export interface UserSessionResponse {
  jwt: string | null;
  refresh_token: string | null;
  id: string;
  experiment_id: string;
  attributes: Attributes;
  authentication_token: string;
}

export const SESSION_COOKIE = '_session_id';
export const JWT_COOKIE = 'cc_jwt';
export const JWT_REFRESH_COOKIE = 'cc_refresh_token';
export const CC_AUTHENTICATED_COOKIE = 'cc_authenticated'; // Used for CDN caching. Set by monolith (via Warden hooks)

export const userSessionEndpoint = '/users/session';

export const fetchUserSession = async (context: GetServerSidePropsContext) => {
  // Forward cookies when fetching in SSR
  try {
    const res = await fetch(
      `${envsInternalExternal.MONOLITH_URL}${userSessionEndpoint}`,
      {
        headers: {
          referer:
            context.req.headers.referer ||
            process.env.NEXT_PUBLIC_BASE_PATH ||
            '',
          cookie: context.req.headers.cookie || '',
          CODECADEMY_CLIENT_IP: requestIp.getClientIp(context.req) ?? '',
          'codecademy-client-ua': context.req.headers['user-agent'] || '',
          ...cfAccessHeaders,
        },
      }
    );

    if (!res.ok) {
      logError(
        new Error(`Failed to fetch user data ${res.status}: ${res.statusText}`)
      );
      return undefined;
    }

    const data = (await res.json()) as UserSessionResponse;

    return data;
  } catch (err) {
    logError(err);
    return undefined;
  }
};

type UseUserSessionArgs = {
  shouldFetch?: boolean;
};

export const useUserSession = (options: UseUserSessionArgs = {}) => {
  const { shouldFetch = true } = options;

  const userFetcher = async () => {
    const res = await fetch(userSessionEndpoint, {
      credentials: 'include',
      headers: {
        ...cfAccessHeaders,
      },
    });

    if (!res.ok) {
      const msg = (await res.json()) as string;
      const err = new Error(`Failed to fetch user data: ${msg}`);
      throw logAndReturnError(err);
    }
    return res.json() as Promise<UserSessionResponse>;
  };

  const swrResult = useSWR<UserSessionResponse>(
    shouldFetch ? userSessionEndpoint : null,
    userFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return swrResult;
};

export const getServerSideSessionId = (context: GetServerSidePropsContext) =>
  context.req?.cookies?.[SESSION_COOKIE];

export const getServerSideJWT = (context: GetServerSidePropsContext) => {
  if (!getServerSideSessionId(context)) {
    return undefined;
  }
  return context.req?.cookies?.[JWT_COOKIE];
};

export const isUserLoggedIn = (cookies: Partial<{ [key: string]: string }>) => {
  const result = cookies && CC_AUTHENTICATED_COOKIE in cookies;
  return result;
};

export const ensureUserJWT = async (context: GetServerSidePropsContext) => {
  const jwt = getServerSideJWT(context);
  if (!jwt && isUserLoggedIn(context.req.cookies)) {
    logWarn('Missing JWT cookie, but user is authenticated');
    const userSession = await fetchUserSession(context);
    if (userSession?.jwt) return userSession.jwt;
  }
  return jwt;
};
