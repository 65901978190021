import * as React from 'react';
import { useMemo, useRef } from 'react';

import {
  AppHeaderAction,
  AppHeaderItem,
  AppHeaderSearch,
  FormattedAppHeaderItems,
} from '..';
import { HeaderHeightArea } from '../HeaderHeightArea';
import { NotificationsDropdown } from '../Notifications/NotificationsDropdown';
import { AppHeaderNotificationSettings } from '../Notifications/types';
import { useHeaderNotifications } from '../Notifications/useHeaderNotifications';
import { AppHeaderListItem } from './AppHeaderElements/AppHeaderListItem';
import { AppHeaderProvider } from './AppHeaderElements/AppHeaderProvider';
import { useHeaderSearch } from './Search/useHeaderSearch';
import {
  appHeaderMobileBreakpoint,
  appHeaderSpacing,
  StyledAppBar,
  StyledNavBar,
} from './shared';
import { mapAppHeaderItemToElement } from './shared/utils';

export * from './Search/consts';

export type AppHeaderProps = AppHeaderAction & {
  items: FormattedAppHeaderItems;
  notifications?: AppHeaderNotificationSettings;
  redirectParam?: string;
  search: AppHeaderSearch;
  isAnon: boolean;
  /**
   * used to conditonally hide the default search icon and notification bell
   */
  hideRightButtonDefaults?: boolean;
  /**
   * temporary solution to allow this to be used in LXStudio. Future Header epic here: GM-1024
   */
  isStandalone?: boolean;
  isTeams?: boolean;
};

export const AppHeader: React.FC<AppHeaderProps> = ({
  action,
  isAnon,
  hideRightButtonDefaults,
  items,
  notifications,
  redirectParam,
  search,
  isStandalone,
  isTeams,
}) => {
  const menuContainerRef = useRef<HTMLUListElement>(null);

  const [notificationsBell, notificationsView] = useHeaderNotifications({
    settings: notifications,
    Renderer: NotificationsDropdown,
  });
  const [searchButton, searchPane] = useHeaderSearch({
    ...search,
  });

  const right = useMemo(() => {
    const defaultItems = hideRightButtonDefaults
      ? []
      : [searchButton, ...(notificationsBell ? [notificationsBell] : [])];

    if (isTeams) {
      const teamsSpecificMenuItems = items.right.slice(0, -1);
      const profile = items.right[items.right.length - 1];

      return [...teamsSpecificMenuItems, ...defaultItems, profile];
    }

    return [...defaultItems, ...items.right];
  }, [
    searchButton,
    notificationsBell,
    hideRightButtonDefaults,
    items,
    isTeams,
  ]);

  const mapItemsToElement = <T extends AppHeaderItem[]>(
    items: T,
    side: 'left' | 'right'
  ) => {
    return items.map((item, index) => {
      const margin = hideRightButtonDefaults
        ? appHeaderSpacing.enterprise
        : appHeaderSpacing.standard;
      return (
        <AppHeaderListItem
          key={item.id}
          mr={margin}
          ml={side === 'right' && index === 0 ? 'auto' : margin}
        >
          {mapAppHeaderItemToElement({
            action,
            item,
            isAnon,
            isStandalone,
            isTeams,
            redirectParam,
          })}
        </AppHeaderListItem>
      );
    });
  };

  return (
    <HeaderHeightArea
      display={{ _: 'none', [appHeaderMobileBreakpoint]: 'block' }}
    >
      <AppHeaderProvider>
        <StyledAppBar aria-label="Main" as="nav">
          <StyledNavBar ref={menuContainerRef}>
            {mapItemsToElement(items.left, 'left')}
            {mapItemsToElement(right, 'right')}
          </StyledNavBar>
        </StyledAppBar>
        {notificationsView}
        {searchPane}
      </AppHeaderProvider>
    </HeaderHeightArea>
  );
};
