import 'focus-visible';

import { ApolloProvider } from '@apollo/client';
import {
  createFontLinks,
  GamutProvider,
  theme,
} from '@codecademy/gamut-styles';
import { EmotionCache } from '@emotion/utils';
import { PagePropsWithApollo, useApollo } from '@mono/apollo';
import {
  Konamimojisplosion,
  PageAlert,
  PageAlerts,
  PageAlertsProvider,
} from '@mono/brand';
import { initErrorLogging, startRUMTracing } from '@mono/data-logging';
import {
  OptimizelyProviderWrapper,
  PagePropsWithOptimizely,
  useOptimizelyFromServer,
} from '@mono/data-optimizely';
import { TrackingProvider } from '@mono/data-use-tracking';
import { UserProvider } from '@mono/data-user';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import React, { useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';

import {
  PagePropsWithSkipBrandedBanner,
  SkipBrandedBannerContext,
  useSkipBrandedBannerFromServer,
} from '~/libs/brandedBanner/skipBrandedBanner';
import { getCache } from '~/libs/emotion/cache';
import { ExperimentsContext } from '~/libs/experiments/ExperimentsContext';
import {
  PagePropsWithExperiments,
  useExperimentsFromServer,
} from '~/libs/experiments/useExperimentsFromServer';
import {
  PagePropsWithFeatureFlags,
  useFeatureFlagsFromServer,
} from '~/libs/FeatureFlags/featureFlags';
import { FeatureFlagsContext } from '~/libs/FeatureFlags/FeatureFlagsContext';
import { getApolloServiceConfig } from '~/libs/getApolloServiceConfig';
import {
  PagePropsWithNextJSContext,
  useIsPreviewNextJS,
  useSSRCookies,
} from '~/libs/nextJSContextViaProps';

import { UserVisitProvider } from '../contexts/UserVisitContext';
import { LocaleContext } from '../libs/locale/localeContext';
import {
  PagePropsWithLocale,
  useServerLocale,
} from '../libs/locale/localeViaProps';
import { ErrorBoundary } from './ErrorBoundary';
import { ErrorPage } from './ErrorPage';
import { TrackingIntegrations } from './TrackingIntegrations';

startRUMTracing({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  version: process.env.NEXT_PUBLIC_APP_VERSION,
  env: process.env.NEXT_PUBLIC_DATADOG_ENV,
  service: process.env.NEXT_PUBLIC_APP_NAME,
});

initErrorLogging(
  {
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    env: process.env.NEXT_PUBLIC_DATADOG_ENV,
    service: process.env.NEXT_PUBLIC_APP_NAME,
  },
  ['staging', 'production'].includes(process.env.NEXT_PUBLIC_ENV)
);

interface AppProps extends Omit<NextAppProps, 'pageProps'> {
  pageProps: AppPageProps;
}

export interface AppPageProps
  extends PagePropsWithApollo,
    PagePropsWithNextJSContext,
    PagePropsWithFeatureFlags,
    PagePropsWithExperiments,
    PagePropsWithOptimizely,
    PagePropsWithLocale,
    PagePropsWithSkipBrandedBanner {
  children?: React.ReactNode;
}

export function App({
  Component,
  pageProps,
  cache,
}: AppProps & { cache: EmotionCache | undefined }) {
  const activeCache = useMemo(() => cache ?? getCache(), [cache]);
  const apolloClient = useApollo(pageProps, getApolloServiceConfig());
  const cookies = useSSRCookies(pageProps);
  const isPreviewNextJS = useIsPreviewNextJS(pageProps);
  const featureFlags = useFeatureFlagsFromServer(pageProps);
  const experiments = useExperimentsFromServer(pageProps);
  const optimizely = useOptimizelyFromServer(pageProps);
  const locale = useServerLocale(pageProps);
  const skipBrandedBanner = useSkipBrandedBannerFromServer(pageProps);

  return (
    <UserProvider>
      <CookiesProvider cookies={cookies}>
        <ApolloProvider client={apolloClient}>
          <LocaleContext.Provider value={locale}>
            <GamutProvider cache={activeCache} theme={theme}>
              <OptimizelyProviderWrapper optimizely={optimizely}>
                <FeatureFlagsContext.Provider value={{ featureFlags }}>
                  <TrackingProvider
                    apiBaseUrl={process.env.NEXT_PUBLIC_BASE_PATH || ''}
                    verbose={
                      !['production', 'test'].includes(process.env.NODE_ENV)
                    }
                    sourceCodebase="portal-app"
                  >
                    <TrackingIntegrations />
                    <ExperimentsContext.Provider value={{ experiments }}>
                      <SkipBrandedBannerContext.Provider
                        value={skipBrandedBanner}
                      >
                        <PageAlertsProvider>
                          <Head>{createFontLinks()}</Head>
                          <ErrorBoundary fallback={<ErrorPage />}>
                            <UserVisitProvider>
                              <Component {...pageProps} />
                              <PageAlerts
                                extra={getPreviewAlert(isPreviewNextJS)}
                              />
                              <Konamimojisplosion />
                            </UserVisitProvider>
                          </ErrorBoundary>
                        </PageAlertsProvider>
                      </SkipBrandedBannerContext.Provider>
                    </ExperimentsContext.Provider>
                  </TrackingProvider>
                </FeatureFlagsContext.Provider>
              </OptimizelyProviderWrapper>
            </GamutProvider>
          </LocaleContext.Provider>
        </ApolloProvider>
      </CookiesProvider>
    </UserProvider>
  );
}

const getPreviewAlert = (isPreviewNextJS: boolean) =>
  isPreviewNextJS
    ? [
        {
          type: 'notice',
          message: 'Using Contentful Preview Data / In NextJS Preview Mode',
        } as PageAlert,
      ]
    : undefined;
