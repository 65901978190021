import { ContentItemContent } from '@mono/data-codegen/client/graphql-gateway/graphql';

import { userIsLoggedIn } from './fetchUser';
import type { UserResponseData } from './types';

export const getUserBypassProRequirementTypes = (user: UserResponseData) => {
  const hasBypassProRequirement = (entitlement: string) => {
    const entitlements = user && userIsLoggedIn(user) ? user.entitlements : [];
    return entitlements?.includes(entitlement);
  };

  const bypassProRequirementTypes: ContentItemContent[] = [];
  if (hasBypassProRequirement('quizzes')) {
    bypassProRequirementTypes.push(ContentItemContent.Quiz);
  }
  if (hasBypassProRequirement('practice_projects')) {
    bypassProRequirementTypes.push(ContentItemContent.Project);
  }

  return bypassProRequirementTypes;
};
